import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { backend } from "../../../../path";
import styled from "styled-components";
import "./contact.css";

function ContactUs() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone_no: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSubmit = () => {
    const requiredFields = ["name", "email", "phone_no", "message"];
    const emptyFields = requiredFields.filter((field) => !formData[field]);

    if (emptyFields.length > 0) {
      toast.error("Please fill in all required fields", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return; // Prevent further execution
    }

    fetch(backend + "contactus", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (!response.ok) {
          console.error(
            "Failed to submit form:",
            response.status,
            response.statusText
          );
          toast.error("Failed to submit form. Please try again later", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          return;
        }
        // Reset form after successful submission
        setFormData({
          name: "",
          email: "",
          phone_no: "",
          message: "",
        });
        toast.success("Form submitted successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((error) => {
        console.error("Error submitting form:", error);
        toast.error("Failed to submit form. Please try again later", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  return (
    <main>
      <div className="page-header breadcrumb-wrap">
        <div className="container">
          <div className="breadcrumb">
            <a href="/" className="breadcrumb-item d-inline-block" title="Home">
              {" "}
              Home{" "}
            </a>
            <span></span>
            <div className="breadcrumb-item d-inline-block active">
              {" "}
              Contact Us{" "}
            </div>
          </div>
        </div>
      </div>
      <div className="wrapper">
        <section className="">
          <Header>
            <H2>Get in Touch with Us</H2>
          </Header>
          <Contents>
            <p>
              At Keralaspecial.in, we are passionate about sharing the beauty
              and heritage of Kerala with the world. For any inquiries or
              assistance, please feel free to reach out to us using the
              following contact details:
            </p>
            <br />
            <ul>
              <li>
                <b>Address:</b> 38, Thycaud Elankom Nagar, Thycaud, Trivandrum
                695014
              </li>
              <li>
                <b>Phone:</b> (91)-7356629493, 8891196910
              </li>
              <li>
                <b>Email:</b>{" "}
                <a href="mailto:info@keralaspecial.in">info@keralaspecial.in</a>
              </li>
            </ul>
            <p>
              We look forward to hearing from you! <br /> <br />
              <br />
              We strive to provide an exceptional online experience, reflecting
              the warmth and hospitality of our home state. If you have any
              questions, comments, or suggestions, please don't hesitate to get
              in touch. Our dedicated team is here to help!
            </p>
          </Contents>
          <Contents className="form">
            {/* <h2>Contact with Us</h2> */}
            <ImgContainer className="imgContainer">
              <img src="/storage/general/contact.png" alt="Contact form" />
            </ImgContainer>
            {/* Contact form */}
            <Form>
              <div className="box">
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="box">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="box">
                <label htmlFor="phone_no">Phone Number</label>
                <input
                  type="text"
                  id="phone_no"
                  name="phone_no"
                  value={formData.phone_no}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="box">
                <label htmlFor="message">Message</label>
                <textarea
                  type="text"
                  id="message"
                  name="message"
                  rows="3"
                  value={formData.message}
                  onChange={handleChange}
                  required
                ></textarea>
              </div>
              <button
                type="button"
                onClick={handleSubmit}
                className="btn btn-primary"
              >
                Submit
              </button>
            </Form>
          </Contents>
        </section>
      </div>
    </main>
  );
}

export default ContactUs;
const Header = styled.div``;
const H2 = styled.h2`
  font-weight: 500;
  padding: 20px 0;

  @media screen and (max-width: 640px) {
    font-size: 25px;
  }
`;
const Contents = styled.div`
  font-size: 17px;
  p {
    font-size: 17px;
    @media screen and (max-width: 640px) {
      font-size: 15px;
    }
  }
  li,
  a {
    padding: 10px 0;
  }
  margin-bottom: 30px;

  &.form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    @media screen and (max-width: 880px) {
      grid-template-columns: 1fr;
    }
  }
  @media screen and (max-width: 640px) {
    font-size: 15px;
  }
`;
const Form = styled.form`
  margin-top: 50px;
  input,
  textarea {
    padding: 10px 20px;
    margin-bottom: 20px;
    border: 1px solid #00000025;
  }
  button {
    background-color: #094c27;
    border: none;
  }

  input:focus,
  textarea:focus {
    border: 2px solid #0e703a;
  }
`;
const ImgContainer = styled.div`
  height: 100%;
  width: 100%;
  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    display: block;
  }
  @media screen and (max-width: 880px) {
    display: none;
  }
`;
