import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styled from "styled-components";
import { PiShoppingCartThin } from "react-icons/pi";
import { CiHeart } from "react-icons/ci";
import { VscHeartFilled } from "react-icons/vsc";
import { HiOutlineArrowLeft, HiOutlineArrowRight } from "react-icons/hi2";
import { backend, producturl } from "../../../path";
import { trimDescriptionToTen } from "../../../utils/trimDescription";
import StarIcon from "@mui/icons-material/Star";
import { Rating } from "@mui/material";
import { toast } from "react-toastify";

const TopSellingComponent = (props) => {
  const [value, setValue] = React.useState(2);
  const [hover, setHover] = React.useState(-1);
  const sliderRef = useRef(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState();
  const [error, setError] = useState();
  const navigate = useNavigate();
  const [authenticated, setAuthenticated] = useState(
    JSON.parse(localStorage.getItem("userdata"))
  );
  const fetchData = () => {
    setLoading(true);

    fetch(backend + "getallspecialproduts", {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setData(data.getTopSelling);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    fetchData();
  }, []);

  const handleAddCart = async (id) => {
    if (authenticated === null) {
      window.location.href = "/login";
      return;
    }

    const formData = {
      status: 1,
      quantity: 1,
      product_id: id,
    };

    try {
      const response = await fetch(backend + "addtocartuserside", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authenticated?.access_token}`,
        },
        body: JSON.stringify(formData),
      });

      if (response.status === 200) {
        toast.success("Item added to cart", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        props.setCartChange(!props.cartChange);
        await new Promise((resolve) => setTimeout(resolve, 3000));
        // navigate("/cart");
      } else if (response.status === 409) {
        toast.error("Conflict occurred. Please try again later.", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (response.status === 401) {
        toast.error("Item already exits in the cart", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error("Something went wrong. Please try again later.", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again later.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  const handleAddToWishList = async (id) => {
    if (authenticated === null) {
      window.location.href = "/login";
      return;
    }

    const formData = {
      status: 0,
      product_id: id,
    };

    try {
      const response = await fetch(backend + "addtowishlistuserside", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authenticated?.access_token}`,
        },
        body: JSON.stringify(formData),
      });

      if (response.status === 200) {
        toast.success("Item added to wishlist", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        props.setWishChange(!props.wishChange);
        await new Promise((resolve) => setTimeout(resolve, 3000));
        // navigate("/cart");
      } else if (response.status === 409) {
        toast.error("Conflict occurred. Please try again later.", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (response.status === 401) {
        toast.error("Item already exits in the wishlist", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error("Something went wrong. Please try again later.", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again later.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  const handlePrev = () => {
    sliderRef.current.slickPrev();
  };
  const handleProductRoute = (productId) => {
    navigate(`/product/${productId}`);
    // window.location.reload();
  };
  const handleNext = () => {
    sliderRef.current.slickNext();
  };
  function getFirstImageUrl(data) {
    const producturl = "https://keralaspecial.in/server/public/upload/products";

    // Check if data is a valid string
    if (typeof data !== "string" || !data.trim()) {
      console.error("Invalid data provided:", data);
      return null; // Return null or an appropriate default value
    }

    try {
      // Use a regular expression to extract all strings inside double quotes
      let matches = data
        .match(/"([^"]*)"/g)
        ?.map((item) => item.replace(/"/g, ""));

      // Check if matches exist and get the first value
      if (matches && matches.length > 0) {
        let firstImage = matches[0];
        // Join the base URL with the first image filename
        return `${producturl}/${firstImage}`;
      } else {
        console.error("No matches found in data:", data);
        return null;
      }
    } catch (error) {
      console.error("Error processing data:", error);
      return null;
    }
  }
  const Topsettings = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
    ],
  };
  console.log("top selling data", data);

  return (
    <TopSelling>
      <h5>Top Selling</h5>
      <Container className="top-selling">
        <div className="slider-container">
          <Slider ref={sliderRef} {...Topsettings}>
            {data &&
              data.length > 0 &&
              data.map((item) => {
                return (
                  <Card key={item.product_id}>
                    <Top>
                      <LikeBtn
                        onClick={() => handleAddToWishList(item.product_id)}
                      >
                        {true ? <CiHeart /> : <VscHeartFilled />}
                      </LikeBtn>
                      <ImageContainer
                        onClick={() => handleProductRoute(item.product_slug)}
                        className="top-selling"
                      >
                        <img
                          src={producturl + item.thumbnail}
                          alt="Image not found"
                        />
                      </ImageContainer>
                    </Top>
                    <Bottom className="top-selling">
                      <div>
                        <h3
                          onClick={() => handleProductRoute(item.product_slug)}
                        >
                          {item.product_name}
                        </h3>
                        <Rating
                          name="hover-feedback"
                          value={item.average_stars}
                          precision={0.5}
                          onChange={(event, newValue) => {
                            setValue(newValue);
                          }}
                          onChangeActive={(event, newHover) => {
                            setHover(newHover);
                          }}
                          emptyIcon={
                            <StarIcon
                              style={{ opacity: 0.55 }}
                              fontSize="inherit"
                            />
                          }
                        />
                        <DescriptionButton>
                          <p>{trimDescriptionToTen(item.description)}</p>
                          <div>
                            <button
                              onClick={() => handleAddCart(item.product_id)}
                            >
                              <PiShoppingCartThin />
                            </button>
                          </div>
                        </DescriptionButton>
                      </div>
                    </Bottom>
                  </Card>
                );
              })}
          </Slider>
        </div>
      </Container>
      <ButtonContainer>
        <TopSellingButton onClick={handlePrev}>
          <HiOutlineArrowLeft />
        </TopSellingButton>
        <TopSellingButton onClick={handleNext}>
          <HiOutlineArrowRight />
        </TopSellingButton>
      </ButtonContainer>
    </TopSelling>
  );
};

export default TopSellingComponent;
const TopSelling = styled.div``;

const H2 = styled.h2`
  font-weight: 600;
  padding: 15px 0;
  color: #000;
`;
const Container = styled.div`
  border: 1px solid #636363;
  border-radius: 10px;
  padding: 10px;
  margin: 20px 0;
  .slick-slide {
    padding-right: 15px;
    height: unset;
    @media screen and (max-width: 600px) {
      padding-right: 0px;
    }
  }
`;
const Card = styled.div`
  &.popular {
    border: 1px solid black;
  }
`;
const Top = styled.div`
  position: relative;
`;
const LikeBtn = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  background-color: #f0f0f0;
  display: inline-block;
  padding: 10px;
  svg {
    font-size: 20px;
    color: #ff0000;
  }
`;
const ImageContainer = styled.div`
  height: 300px;
  width: 100%;
  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
    display: block;
  }
  @media screen and (max-width: 540px) {
    height: 200px;
    img {
      object-fit: contain;
    }
  }
`;
const Bottom = styled.div`
  &.top-selling {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    align-items: end;
    margin: 20px 0;
    padding: 5px 15px;
    button {
      border: none;
      background-color: #389763;
      border-radius: 6px;
      padding: 8px 10px;
      svg {
        font-size: 20px;
        color: #fff;
      }
    }
  }
  h3 {
    font-size: 20px;
    min-height: 50px;
  }
`;
const P = styled.p`
  padding: 15px 0;
  p {
    color: #000;
  }
  color: #000;
`;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  width: 100%;
  gap: 20px;
`;
const TopSellingButton = styled.div`
  background-color: #084c27;
  padding: 10px;
  cursor: pointer;
  border-radius: 6px;
  svg {
    font-size: 20px;
    color: #fff;
  }
`;
const DescriptionButton = styled.div`
  display: flex;
  align-items: end;
  justify-content: space-between;

  @media screen and (max-width: 640px) {
    flex-direction: column;
    align-items: start;
  }
`;
