import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

const OrderChart = ({ orders }) => {
  console.log(orders);

  // Extract product names and order quantities
  const productNames = orders?.data.map((order) =>
    order.product_name.length > 20
      ? order.product_name.slice(0, 20) + "..."
      : order.product_name
  );

  const quantities = orders?.data.map((order) => order.quantity);

  const data = {
    labels: productNames,
    datasets: [
      {
        label: "Quantity Ordered",
        data: quantities,
        backgroundColor: "rgba(75, 192, 192, 0.6)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return <Bar data={data} options={options} />;
};

export default OrderChart;
