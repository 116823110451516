import React, { useEffect, useState } from "react";
import { backend, categoryurl } from "../../../path";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "../../../App.css";
import styled from "styled-components";
import { GrFacebookOption } from "react-icons/gr";
import { LiaInstagram, LiaUserCircle } from "react-icons/lia";
import { AiFillYoutube } from "react-icons/ai";
import { RiTwitterXLine } from "react-icons/ri";
import { GoHome } from "react-icons/go";
import { MdOutlineCategory } from "react-icons/md";
import { PiShoppingCartThin } from "react-icons/pi";
import {
  IoCloseOutline,
  IoPersonOutline,
  IoSearchOutline,
} from "react-icons/io5";
import { CiHeart, CiLogin } from "react-icons/ci";

import { RxDashboard, RxHamburgerMenu } from "react-icons/rx";
import { BsChevronDown } from "react-icons/bs";

function HeaderMainNew(props) {
  const navigate = useNavigate();
  const [subcategory, setSubcategory] = useState(false);
  const [subcategoryItems, setSubcategoryItems] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [data, setData] = useState([]);
  const [name, setName] = useState([]);
  const [products, setProducts] = useState([]);
  const [cartData, setCartData] = useState([]);
  const [wish, setWish] = useState([]);
  const [loading, setLoading] = useState();
  const [error, setError] = useState();
  const [search, setSearch] = useState("");
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [isMobileHeaderActive, setMobileHeaderActive] = useState(false);
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const [isCategoriesVisible, setCategoriesVisible] = useState(false);
  const [openLoginDropdown, setOpenLoginDropdown] = useState(false);
  const [openSearch, setOpenSearch] = useState();
  const [authenticated, setAuthenticated] = useState(
    JSON.parse(localStorage.getItem("userdata"))
  );
  const location = useLocation();

  // Function to toggle the visibility of the mobile header
  const toggleMobileHeader = () => {
    setMobileHeaderActive(!isMobileHeaderActive);
  };

  useEffect(() => {
    setMobileHeaderActive(false);
    setOpenLoginDropdown(false);
    setCategoriesVisible(false);
  }, [location]);
  const handleLogout = () => {
    localStorage.clear();
    navigate("/");
    window.location.reload();
  };

  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible);
  };

  const fetchData = () => {
    // Assuming setData, setError, and setLoading are state functions from useState
    setLoading(true);

    fetch(backend + "getcategorywithsubcategory", {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setData(data);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchProducts = () => {
    setLoading(true);

    fetch(backend + "getallproductsfrontend", {
      method: "GET",
    })
      .then((response) => {
        if (response.status === 302) {
          const redirectUrl = response.headers.get("Location");
          console.error(`Redirecting to: ${redirectUrl}`);
          throw new Error(`Redirection to ${redirectUrl}`);
        }
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setProducts(data);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
    fetchProducts();
  }, [selectedCategory]);

  const handleSubCategory = (id) => {
    const selectedCategory = data.find((item) => item.category_id === id);
    if (selectedCategory) {
      setSubcategoryItems(selectedCategory.subcategory || []);
    }
    setSubcategory(true);
    setSelectedCategory(id);
  };

  const handleSubCategoryRoute = (subCategoryId) => {
    // sessionStorage.setItem("subCategoryId", subCategoryId);
    toggleDropdown();
    props?.SearchFetch();
    navigate(`/subcategory/${subCategoryId}`);
    // window.location.reload();
  };

  const handleCategoryMain = (cat) => {
    // sessionStorage.setItem("categoryId", cat);
    // setSubcategory(false)
    props?.SearchFetch();
    navigate(`/category/${cat}`);
    // window.location.reload();
  };

  useEffect(() => {
    if (authenticated) {
      fetchDataCart();
      fetchDataWish();
    }
  }, [authenticated, props.cartChange, props.wishChange]);

  const fetchDataCart = () => {
    setLoading(true);

    fetch(backend + `getcartbyuseriduserside`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authenticated?.access_token}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setCartData(data);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const fetchDataWish = () => {
    setLoading(true);

    fetch(backend + `getwishlistbyuseriduserside`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authenticated?.access_token}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setWish(data);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const fetchName = () => {
    setLoading(true);

    const url = authenticated?.usertype === 1 ? "getusername" : "getadminname";

    fetch(backend + url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authenticated?.access_token}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setName(data);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    if (authenticated) {
      fetchName();
    }
  }, [authenticated]);

  const [openCategory, setOpenCategory] = useState(null);

  // Function to handle category click
  const handleCategoryClick = (categoryId) => {
    // Toggle the category: if already open, close it; if closed, open it
    setOpenCategory(openCategory === categoryId ? null : categoryId);
  };

  const getOptions = (inputValue) => {
    setSearch(inputValue);
    if (inputValue.trim() === "") {
      setFilteredOptions([]);
      return;
    }

    // Filter products based on input value
    const filtered = products.filter((product) =>
      product.product_name.toLowerCase().includes(inputValue.toLowerCase())
    );
    setFilteredOptions(filtered.slice(0, 18));
  };
  const handleSelectSearch = (inputValue) => {
    props.setSearch(inputValue);
    setFilteredOptions([]);
  };

  const handleProductRoute = (productId) => {
    setSearch("");
    props.setSearch("");
    setFilteredOptions([]);
    navigate(`/product/${productId}`);
  };
  const handleOpenLoginDropDown = () => {
    setOpenLoginDropdown(!openLoginDropdown);
  };
  const handleOpenCategory = () => {
    setCategoriesVisible(!isCategoriesVisible);
  };
  return (
    <>
      {authenticated?.usertype === 1 ? (
        <>
          <Header>
            <Top>
              <Left>
                <Socials>
                  <a
                    target="_blank"
                    href="https://www.facebook.com/myKeralaspecial?mibextid=qi2Omg&rdid=kccxW9NpVX3BFzrU&share_url=https%3A%2F%2Fwww.facebook.com%2Fshare%2FXM1oRDDkvagcQKW9%2F%3Fmibextid%3Dqi2Omg"
                  >
                    <GrFacebookOption />
                  </a>
                  <a
                    target="_blank"
                    href="https://www.instagram.com/keralaspecial/"
                  >
                    <LiaInstagram />
                  </a>
                  <a
                    target="_blank"
                    href="https://www.youtube.com/@keralaspecial5256"
                  >
                    <AiFillYoutube />
                  </a>
                  <a
                    target="_blank"
                    href="https://x.com/mykeralaspecial?t=5jaIdWgLfUhVzxywcQto9g"
                  >
                    <RiTwitterXLine />
                  </a>
                </Socials>
              </Left>
              <Right>
                <a href="tel:+917356629493">+ 91 735 6629 493</a>
              </Right>
            </Top>
            <Main>
              <div className="wrapper">
                <Container>
                  <Link to="/">
                    <ImageContainer>
                      <img src="/storage/general/ks-logo32.png" />
                    </ImageContainer>
                  </Link>
                  <Nav>
                    <NavInner>
                      <Link to="/">Home</Link>
                      <Link to="/about-us">About Us</Link>
                      <Link to="blog">Blogs</Link>
                      <Link to="contact-us">Contact Us</Link>
                    </NavInner>

                    <SearchContainer>
                      <Input
                        type="text"
                        // className="input-search-product"
                        placeholder="Search for items...."
                        // value={props.search}
                        value={search}
                        onChange={(e) => getOptions(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            handleSelectSearch(search);
                          }
                        }} // onChange={(e) => props.setSearch(e.target.value)}
                        autocomplete="off"
                      />
                      <IoSearchOutline />
                      {filteredOptions.length > 0 && (
                        <SearchSuggestions>
                          {filteredOptions.map((option, index) => (
                            <Suggestion
                              key={index}
                              onClick={() =>
                                handleProductRoute(option?.product_slug)
                              }
                            >
                              {option.product_name}
                            </Suggestion>
                          ))}
                        </SearchSuggestions>
                      )}
                    </SearchContainer>
                  </Nav>
                  <Section className="top-btns">
                    <Buttons>
                      <Button className="notification-style">
                        {wish && wish.length > 0 ? (
                          <Number>{wish.length}</Number>
                        ) : null}
                        <div>
                          <span>
                            <CiHeart />
                          </span>
                          <Link to="/wishlist">Wishlist</Link>
                        </div>
                      </Button>
                      <Button className="notification-style">
                        {cartData && cartData.length > 0 ? (
                          <Number>{cartData.length}</Number>
                        ) : null}
                        <div>
                          <span>
                            <PiShoppingCartThin />
                          </span>
                          <Link to="/cart">Cart</Link>
                        </div>
                      </Button>
                      {authenticated ? (
                        <Button
                          className="user"
                          onClick={handleOpenLoginDropDown}
                        >
                          <span>
                            <IoPersonOutline />
                          </span>
                          <a style={{ textTransform: "capitalize" }}>
                            {authenticated && `${name}`}
                          </a>
                        </Button>
                      ) : (
                        <Button onClick={handleOpenLoginDropDown}>Login</Button>
                      )}

                      {!authenticated ? (
                        <LogoutModal
                          className={openLoginDropdown ? "active" : ""}
                        >
                          {" "}
                          <div className="btn-wrap">
                            <Link
                              to="/login"
                              onClick={() => props?.SearchFetch()}
                            >
                              {" "}
                              <button className="login-btn">LOGIN</button>
                            </Link>
                            <Link to="/register">
                              <button className="login-btn">REGISTER</button>
                            </Link>
                          </div>
                        </LogoutModal>
                      ) : (
                        <LogoutModal
                          className={openLoginDropdown ? "active" : ""}
                        >
                          <div className="btn-wrap">
                            <Link
                              to="/myorders"
                              onClick={() => props?.SearchFetch()}
                            >
                              {" "}
                              <button className="login-btn">MY ORDERS</button>
                            </Link>
                          </div>
                          <div className="btn-wrap" onClick={handleLogout}>
                            <Link to="/" onClick={() => props?.SearchFetch()}>
                              {" "}
                              <button className="login-btn">LOGOUT</button>
                            </Link>
                          </div>
                        </LogoutModal>
                      )}
                    </Buttons>
                  </Section>
                </Container>
              </div>
            </Main>
            <ItemContainer>
              <Items>
                {data.slice(0, -1).map((item, i) => (
                  <Item
                    // style={{
                    //   backgroundColor:
                    //     selectedCategory === item.category_id && subcategory
                    //       ? "#e1f0da"
                    //       : "transparent",
                    // }}
                    key={i}
                    onMouseEnter={() => handleSubCategory(item.category_id)}
                    // onClick={() => setSubcategory(false)}
                    onClick={() => {
                      handleCategoryMain(item.category_slug);
                    }}
                  >
                    <ItemDiv>
                      <Link
                        to=""
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                          color: "#000",
                          fontSize: "15px",
                        }}
                      >
                        <div>
                          <img
                            src={categoryurl + item.logo}
                            width={30}
                            height={30}
                          />{" "}
                        </div>
                        <div>{item.category_name}</div>
                      </Link>
                      <Arrow>
                        <BsChevronDown />
                      </Arrow>
                    </ItemDiv>
                    {subcategory && (
                      <Dropdown className="ml-3">
                        {subcategoryItems.length > 0 ? (
                          subcategoryItems.map((subItem, index) => (
                            <li key={index}>
                              <div
                                // to={`/subcategory/${subItem.sub_category_id}`}
                                onClick={(e) => {
                                  e.stopPropagation(); // Prevent bubbling to parent
                                  handleSubCategoryRoute(
                                    subItem.sub_category_slug
                                  );
                                }}
                                // to={"/product-list"}
                              >
                                {subItem.sub_category_name}
                              </div>
                            </li>
                          ))
                        ) : (
                          <ul className="ml-3">
                            <li>coming soon</li>
                          </ul>
                        )}
                      </Dropdown>
                    )}
                  </Item>
                ))}
              </Items>
            </ItemContainer>
          </Header>
          {/* mobile container */}
          <MobileHeader>
            <Link to="/">
              <ImageContainer>
                <img src="/storage/general/ks-logo32.png" />
              </ImageContainer>
            </Link>
            <SearchContainer className="mobile-search">
              <Input
                className="mobile-search"
                type="text"
                // className="input-search-product"
                placeholder="Search"
                // value={props.search}
                value={search}
                onChange={(e) => getOptions(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSelectSearch(search);
                  }
                }} // onChange={(e) => props.setSearch(e.target.value)}
                autocomplete="off"
              />
              <IoSearchOutline />
              {filteredOptions.length > 0 && (
                <SearchSuggestions className="mobile">
                  {filteredOptions.map((option, index) => (
                    <Suggestion
                      key={index}
                      onClick={() => handleProductRoute(option?.product_slug)}
                    >
                      {option.product_name}
                    </Suggestion>
                  ))}
                </SearchSuggestions>
              )}
            </SearchContainer>
            {/* <DropSearch>
              <IoSearchOutline onClick={() => setOpenSearch(!openSearch)} />
              {openSearch && (
                <MobileSearch>
                  <SearchContainer className="drop-mobile-search">
                    <Input
                      className="mobile-search"
                      type="text"
                      // className="input-search-product"
                      placeholder="Search"
                      // value={props.search}
                      value={search}
                      onChange={(e) => getOptions(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleSelectSearch(search);
                        }
                      }} // onChange={(e) => props.setSearch(e.target.value)}
                      autocomplete="off"
                    />
                    <IoSearchOutline />
                    {filteredOptions.length > 0 && (
                      <SearchSuggestions className="mobile-drop">
                        {filteredOptions.map((option, index) => (
                          <Suggestion
                            key={index}
                            onClick={() =>
                              handleProductRoute(option?.product_slug)
                            }
                          >
                            {option.product_name}
                          </Suggestion>
                        ))}
                      </SearchSuggestions>
                    )}
                  </SearchContainer>
                </MobileSearch>
              )}
            </DropSearch> */}
          </MobileHeader>
          <Slider className={isMobileHeaderActive ? "active" : ""}>
            <MobileTop>
              <Link to="/">
                <ImageContainer>
                  <img src="/storage/general/ks-logo32.png" />
                </ImageContainer>
              </Link>
              <div onClick={toggleMobileHeader}>
                <IoCloseOutline />
              </div>
            </MobileTop>
            <Socials className="mobile">
              <a
                target="_blank"
                href="https://www.facebook.com/myKeralaspecial?mibextid=qi2Omg&rdid=kccxW9NpVX3BFzrU&share_url=https%3A%2F%2Fwww.facebook.com%2Fshare%2FXM1oRDDkvagcQKW9%2F%3Fmibextid%3Dqi2Omg"
              >
                <GrFacebookOption />
              </a>
              <a
                target="_blank"
                href="https://www.instagram.com/keralaspecial/"
              >
                <LiaInstagram />
              </a>
              <a
                target="_blank"
                href="https://www.youtube.com/@keralaspecial5256"
              >
                <AiFillYoutube />
              </a>
              <a
                target="_blank"
                href="https://x.com/mykeralaspecial?t=5jaIdWgLfUhVzxywcQto9g"
              >
                <RiTwitterXLine />
              </a>
            </Socials>
            <Buttons className="mobile">
              {!authenticated ? (
                <div className="">
                  <Link to="/login" onClick={() => props?.SearchFetch()}>
                    {" "}
                    <button className="">LOGIN</button>
                  </Link>
                  <Link to="/register">
                    <button className="">REGISTER</button>
                  </Link>
                </div>
              ) : (
                <>
                  <div className="">
                    <Link to="/myorders" onClick={() => props?.SearchFetch()}>
                      {" "}
                      <button className="">MY ORDERS</button>
                    </Link>
                  </div>
                  <div className="" onClick={handleLogout}>
                    <Link to="/" onClick={() => props?.SearchFetch()}>
                      {" "}
                      <button className="">LOGOUT</button>
                    </Link>
                  </div>
                </>
              )}
              {/* <LoginBtn>
                <Link to="/login">Login</Link>
              </LoginBtn>
              <LoginBtn>
                <Link to="/register">Register</Link>
              </LoginBtn> */}
            </Buttons>
            <Nav className="mobile">
              <Link to="/">Home</Link>
              <Link to="/about-us">About Us</Link>
              <Link to="/blog">Blogs</Link>
              <Link to="/contact-us">Contact Us</Link>
            </Nav>
            <P>Copyright</P>
          </Slider>
          <MobileHeaderBottom>
            <Buttons className="bottom">
              <Button onClick={toggleMobileHeader}>
                <GoHome />
              </Button>
              <Button className="mobile" onClick={handleOpenCategory}>
                <span>
                  <RxDashboard />
                </span>
              </Button>
              <Button className="mobile">
                <Number className="mobile">{wish?.length}</Number>
                <Link to="/wishlist">
                  <span>
                    <CiHeart />
                  </span>
                </Link>
              </Button>
              <Button className="mobile">
                <Number className="mobile">{cartData?.length}</Number>
                <Link to="/cart">
                  <span>
                    <PiShoppingCartThin />
                  </span>
                </Link>
              </Button>
              {authenticated ? (
                <Button onClick={handleOpenLoginDropDown}>
                  <div>
                    <span>
                      <LiaUserCircle />
                    </span>
                  </div>
                </Button>
              ) : (
                <Button onClick={handleOpenLoginDropDown}>Login</Button>
              )}
              {!authenticated ? (
                <LogoutModalMobile
                  className={openLoginDropdown ? "active " : ""}
                >
                  {" "}
                  <div className="btn-wrap">
                    <Link to="/login" onClick={() => props?.SearchFetch()}>
                      {" "}
                      <button className="login-btn">LOGIN</button>
                    </Link>
                    <Link to="/register">
                      <button className="login-btn">REGISTER</button>
                    </Link>
                  </div>
                </LogoutModalMobile>
              ) : (
                <LogoutModalMobile
                  className={openLoginDropdown ? "active " : ""}
                >
                  <div className="btn-wrap">
                    <Link to="/myorders" onClick={() => props?.SearchFetch()}>
                      {" "}
                      <button className="login-btn">MY ORDERS</button>
                    </Link>
                  </div>
                  <div className="btn-wrap" onClick={handleLogout}>
                    <Link to="/" onClick={() => props?.SearchFetch()}>
                      {" "}
                      <button className="login-btn">LOGOUT</button>
                    </Link>
                  </div>
                </LogoutModalMobile>
              )}
              <CategoriesModal className={isCategoriesVisible ? "active" : ""}>
                <Items className="mobile">
                  {data.slice(0, -1).map((item, i) => (
                    <Item
                      // style={{
                      //   backgroundColor:
                      //     selectedCategory === item.category_id && subcategory
                      //       ? "#e1f0da"
                      //       : "transparent",
                      // }}
                      key={i}
                      // onMouseEnter={() => handleSubCategory(item.category_id)}
                      // onClick={() => setSubcategory(false)}
                      onClick={() => {
                        handleCategoryMain(item.category_slug);
                      }}
                    >
                      <ItemDiv>
                        <>
                          <div>
                            <img
                              src={categoryurl + item.logo}
                              width={30}
                              height={30}
                            />{" "}
                          </div>
                          <div>{item.category_name}</div>
                        </>
                        {/* <Arrow>
                          <BsChevronDown />
                        </Arrow> */}
                      </ItemDiv>
                    </Item>
                  ))}
                </Items>
              </CategoriesModal>
            </Buttons>
          </MobileHeaderBottom>
        </>
      ) : authenticated?.usertype === 0 ? (
        <div className="header-middle header-middle-ptb-1 d-none d-lg-block fixed-top bg-light">
          <div className="container">
            <div className="header-wrap">
              <Link to="/" style={{ padding: "10px 0" }}>
                <ImageContainer>
                  <img src="/storage/general/ks-logo32.png" />
                </ImageContainer>
              </Link>

              <div className="d-flex justify-content-end w-100">
                <div className="header-action-right">
                  <div className="header-action-2">
                    {/* <div className="header-action-icon-2">
                            <Link to="/onamgift">
                              <span className="lable">
                                <button
                                  type="button"
                                  className="hover-up"
                                  style={{
                                    backgroundColor: '#3bb77e',
                                    color: '#fff',
                                    borderRadius: '5px',
                                    border: '1px solid',
                                    height: '36px',
                                    width: '100px',
                                    bottom: '5px',
                                    animation: 'blink 1s infinite'
                                  }}                          >
                                  ONAM GIFTS
                                </button></span>
                            </Link>
                          </div> */}
                    {/* <div className="header-action-icon-2">
                      <Link to="/wishlist">
                        <img
                          className="svgInject"
                          alt="Wishlist"
                          src="/themes/nest/imgs/theme/icons/icon-heart.svg"
                        />
                        <span className="pro-count blue wishlist-count">
                          {wish?.length}
                        </span>
                      </Link>
                      <Link to="/wishlist">
                        <span className="lable">Wishlist</span>
                      </Link>
                    </div>
                    <div className="header-action-icon-2">
                      <Link className="mini-cart-icon" to="/cart">
                        <img
                          alt="Cart"
                          src="/themes/nest/imgs/theme/icons/icon-cart.svg"
                        />
                        <span className="pro-count blue">
                          {cartData?.length}
                        </span>
                      </Link>
                      <Link to="/cart">
                        <span className="lable">Cart</span>
                      </Link>
                      {cartData?.length < 1 && (
                        <div className="cart-dropdown-wrap cart-dropdown-hm2 cart-dropdown-panel">
                          <span>No products in the cart.</span>
                        </div>
                      )}
                    </div> */}
                    <div className="header-action-icon-2">
                      <Link>
                        <img
                          className="svgInject rounded-circle"
                          alt="Account"
                          src="/themes/nest/imgs/theme/icons/icon-user.svg"
                        />
                      </Link>
                      <Link to="/" onClick={() => props?.SearchFetch()}>
                        <span className="lable me-1">
                          {authenticated ? `${name}` : "Account"}
                        </span>
                      </Link>
                      <div className="cart-dropdown-wrap cart-dropdown-hm2 account-dropdown">
                        <ul>
                          {!authenticated ? (
                            <>
                              <div className="btn-wrap">
                                <Link
                                  to="/login"
                                  onClick={() => props?.SearchFetch()}
                                >
                                  {" "}
                                  <button className="login-btn">LOGIN</button>
                                </Link>
                                <Link to="/register">
                                  <button className="login-btn">
                                    REGISTER
                                  </button>
                                </Link>
                              </div>
                            </>
                          ) : (
                            <div className="btn-wrap" onClick={handleLogout}>
                              <Link to="/" onClick={() => props?.SearchFetch()}>
                                {" "}
                                <button className="login-btn">LOGOUT</button>
                              </Link>
                            </div>
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : authenticated?.usertype === null ? (
        <div className="header-middle header-middle-ptb-1 d-none d-lg-block fixed-top bg-light">
          <div className="container">
            <div className="header-wrap">
              <div className="logo logo-width-1">
                <Link to="/" onClick={() => props?.SearchFetch()}>
                  <img
                    src="/storage/general/ks-logo32.png"
                    alt="Kerala Specials-Logo"
                  />
                </Link>
              </div>
              <div className="d-flex justify-content-end w-100">
                <div className="header-action-right">
                  <div className="header-action-2">
                    {/* <div className="header-action-icon-2">
                            <Link to="/onamgift">
                              <span className="lable">
                                <button
                                  type="button"
                                  className="hover-up"
                                  style={{
                                    backgroundColor: '#3bb77e',
                                    color: '#fff',
                                    borderRadius: '5px',
                                    border: '1px solid',
                                    height: '36px',
                                    width: '100px',
                                    bottom: '5px',
                                    animation: 'blink 1s infinite'
                                  }}                          >
                                  ONAM GIFTS
                                </button></span>
                            </Link>
                          </div> */}
                    <div className="header-action-icon-2">
                      <Link to="/wishlist">
                        <img
                          className="svgInject"
                          alt="Wishlist"
                          src="/themes/nest/imgs/theme/icons/icon-heart.svg"
                        />
                        <span className="pro-count blue wishlist-count">
                          0{" "}
                        </span>
                      </Link>
                      <Link to="/wishlist">
                        <span className="lable">Wishlist</span>
                      </Link>
                    </div>
                    <div className="header-action-icon-2">
                      <Link className="mini-cart-icon" to="/cart">
                        <img
                          alt="Cart"
                          src="/themes/nest/imgs/theme/icons/icon-cart.svg"
                        />
                        <span className="pro-count blue">
                          {cartData?.length}
                        </span>
                      </Link>
                      <Link to="/cart">
                        <span className="lable">Cart</span>
                      </Link>
                      {cartData?.length < 1 && (
                        <div className="cart-dropdown-wrap cart-dropdown-hm2 cart-dropdown-panel">
                          <span>No products in the cart.</span>
                        </div>
                      )}
                    </div>
                    <div className="header-action-icon-2">
                      <Link to="/login" onClick={() => props?.SearchFetch()}>
                        <img
                          className="svgInject rounded-circle"
                          alt="Account"
                          src="/themes/nest/imgs/theme/icons/icon-user.svg"
                        />
                      </Link>
                      <Link to="/" onClick={() => props?.SearchFetch()}>
                        <span className="lable me-1">
                          {authenticated ? `${name}` : <CiLogin />}
                        </span>
                      </Link>
                      <div className="cart-dropdown-wrap cart-dropdown-hm2 account-dropdown">
                        <ul>
                          {!authenticated ? (
                            <>
                              <div className="btn-wrap">
                                <Link
                                  to="/login"
                                  onClick={() => props?.SearchFetch()}
                                >
                                  {" "}
                                  <button className="login-btn">LOGIN</button>
                                </Link>
                                <Link to="/register">
                                  <button className="login-btn">
                                    REGISTER
                                  </button>
                                </Link>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="btn-wrap" onClick={handleLogout}>
                                <Link
                                  to="/"
                                  onClick={() => props?.SearchFetch()}
                                >
                                  {" "}
                                  <button className="login-btn">LOGOUT</button>
                                </Link>
                              </div>
                            </>
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <Header>
            <Top>
              <Left>
                <Socials>
                  <a
                    target="_blank"
                    href="https://www.facebook.com/myKeralaspecial?mibextid=qi2Omg&rdid=kccxW9NpVX3BFzrU&share_url=https%3A%2F%2Fwww.facebook.com%2Fshare%2FXM1oRDDkvagcQKW9%2F%3Fmibextid%3Dqi2Omg"
                  >
                    <GrFacebookOption />
                  </a>
                  <a
                    target="_blank"
                    href="https://www.instagram.com/keralaspecial/"
                  >
                    <LiaInstagram />
                  </a>
                  <a
                    target="_blank"
                    href="https://www.youtube.com/@keralaspecial5256"
                  >
                    <AiFillYoutube />
                  </a>
                  <a
                    target="_blank"
                    href="https://x.com/mykeralaspecial?t=5jaIdWgLfUhVzxywcQto9g"
                  >
                    <RiTwitterXLine />
                  </a>
                </Socials>
              </Left>
              <Right>
                <a href="tel:+917356629493">+ 91 735 6629 493</a>
              </Right>
            </Top>
            <Main>
              <div className="wrapper">
                <Container>
                  <Link to="/">
                    <ImageContainer>
                      <img src="/storage/general/ks-logo32.png" />
                    </ImageContainer>
                  </Link>
                  <Nav>
                    <NavInner>
                      <Link to="/">Home</Link>
                      <Link to="/about-us">About Us</Link>
                      <Link to="blog">Blogs</Link>
                      <Link to="contact-us">Contact Us</Link>
                    </NavInner>
                    <SearchContainer>
                      <Input
                        type="text"
                        // className="input-search-product"
                        placeholder="Search for items...."
                        // value={props.search}
                        value={search}
                        onChange={(e) => getOptions(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            handleSelectSearch(search);
                          }
                        }} // onChange={(e) => props.setSearch(e.target.value)}
                        autocomplete="off"
                      />
                      <IoSearchOutline />
                      {filteredOptions.length > 0 && (
                        <SearchSuggestions>
                          {filteredOptions.map((option, index) => (
                            <Suggestion
                              key={index}
                              onClick={() =>
                                handleProductRoute(option?.product_slug)
                              }
                            >
                              {option.product_name}
                            </Suggestion>
                          ))}
                        </SearchSuggestions>
                      )}
                    </SearchContainer>
                  </Nav>
                  <Section className="top-btns">
                    <Buttons>
                      <Button className="notification-style">
                        {wish && wish.length > 0 ? (
                          <Number>{wish.length}</Number>
                        ) : null}
                        <div>
                          <span>
                            <CiHeart />
                          </span>
                          <Link to={authenticated ? "/wishlist" : "/login"}>
                            Wishlist
                          </Link>
                        </div>
                      </Button>
                      <Button className="notification-style">
                        {cartData && cartData.length > 0 ? (
                          <Number>{cartData.length}</Number>
                        ) : null}
                        <div>
                          <span>
                            <PiShoppingCartThin />
                          </span>
                          <Link to={authenticated ? "/wishlist" : "/login"}>
                            Cart
                          </Link>
                        </div>
                      </Button>
                      {authenticated ? (
                        <Button
                          className="user"
                          onClick={handleOpenLoginDropDown}
                        >
                          <span>
                            <IoPersonOutline />
                          </span>
                          <div>
                            <Link to="/" onClick={() => props?.SearchFetch()}>
                              {" "}
                              {authenticated && `${name}`}
                            </Link>
                          </div>
                        </Button>
                      ) : (
                        <Button
                          onClick={handleOpenLoginDropDown}
                          className=" d-flex items-center gap-1"
                        >
                          <CiLogin /> Account
                        </Button>
                      )}

                      {!authenticated ? (
                        <LogoutModal
                          className={openLoginDropdown ? "active" : ""}
                        >
                          {" "}
                          <div className="btn-wrap">
                            <Link
                              to="/login"
                              onClick={() => props?.SearchFetch()}
                            >
                              {" "}
                              <button className="login-btn">LOGIN</button>
                            </Link>
                            <Link to="/register">
                              <button className="login-btn">REGISTER</button>
                            </Link>
                          </div>
                        </LogoutModal>
                      ) : (
                        <LogoutModal
                          className={openLoginDropdown ? "active" : ""}
                        >
                          <div className="btn-wrap">
                            <Link
                              to="/myorders"
                              onClick={() => props?.SearchFetch()}
                            >
                              {" "}
                              <button className="login-btn">MY ORDERS</button>
                            </Link>
                          </div>
                          <div className="btn-wrap" onClick={handleLogout}>
                            <Link to="/" onClick={() => props?.SearchFetch()}>
                              {" "}
                              <button className="login-btn">LOGOUT</button>
                            </Link>
                          </div>
                        </LogoutModal>
                      )}
                    </Buttons>
                  </Section>
                </Container>
              </div>
            </Main>
            <ItemContainer>
              <Items>
                {data.slice(0, -1).map((item, i) => (
                  <Item
                    // style={{
                    //   backgroundColor:
                    //     selectedCategory === item.category_id && subcategory
                    //       ? "#e1f0da"
                    //       : "transparent",
                    // }}
                    key={i}
                    onMouseEnter={() => handleSubCategory(item.category_id)}
                    // onClick={() => setSubcategory(false)}
                    onClick={() => {
                      handleCategoryMain(item.category_slug);
                    }}
                  >
                    <ItemDiv>
                      <Link
                        to=""
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                          color: "#000",
                          fontSize: "15px",
                        }}
                      >
                        <div>
                          <img
                            src={categoryurl + item.logo}
                            width={30}
                            height={30}
                          />{" "}
                        </div>
                        <div>{item.category_name}</div>
                      </Link>
                      <Arrow>
                        <BsChevronDown />
                      </Arrow>
                    </ItemDiv>
                    {subcategory && (
                      <Dropdown className="ml-3">
                        {subcategoryItems.length > 0 ? (
                          subcategoryItems.map((subItem, index) => (
                            <li key={index}>
                              <div
                                // to={`/subcategory/${subItem.sub_category_id}`}

                                // to={"/product-list"}
                                onClick={(e) => {
                                  e.stopPropagation(); // Prevent bubbling to parent
                                  handleSubCategoryRoute(
                                    subItem.sub_category_slug
                                  );
                                }}
                              >
                                {subItem.sub_category_name}
                              </div>
                            </li>
                          ))
                        ) : (
                          <ul className="ml-3">
                            <li>coming soon</li>
                          </ul>
                        )}
                      </Dropdown>
                    )}
                  </Item>
                ))}
              </Items>
            </ItemContainer>
          </Header>
          {/* mobile container */}
          <MobileHeader>
            <Link to="/">
              <ImageContainer>
                <img src="/storage/general/ks-logo32.png" />
              </ImageContainer>
            </Link>
            <SearchContainer className="mobile-search">
              <Input
                className="mobile-search"
                type="text"
                // className="input-search-product"
                placeholder="Search"
                // value={props.search}
                value={search}
                onChange={(e) => getOptions(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSelectSearch(search);
                  }
                }} // onChange={(e) => props.setSearch(e.target.value)}
                autocomplete="off"
              />
              <IoSearchOutline />
              {filteredOptions.length > 0 && (
                <SearchSuggestions className="mobile">
                  {filteredOptions.map((option, index) => (
                    <Suggestion
                      key={index}
                      onClick={() => handleProductRoute(option?.product_slug)}
                    >
                      {option.product_name}
                    </Suggestion>
                  ))}
                </SearchSuggestions>
              )}
            </SearchContainer>
            {/* <DropSearch>
              <IoSearchOutline onClick={() => setOpenSearch(!openSearch)} />
              {openSearch && (
                <MobileSearch>
                  <SearchContainer className="drop-mobile-search">
                    <Input
                      className="mobile-search"
                      type="text"
                      // className="input-search-product"
                      placeholder="Search"
                      // value={props.search}
                      value={search}
                      onChange={(e) => getOptions(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          handleSelectSearch(search);
                        }
                      }} // onChange={(e) => props.setSearch(e.target.value)}
                      autocomplete="off"
                    />
                    <IoSearchOutline />
                    {filteredOptions.length > 0 && (
                      <SearchSuggestions className="mobile-drop">
                        {filteredOptions.map((option, index) => (
                          <Suggestion
                            key={index}
                            onClick={() =>
                              handleProductRoute(option?.product_slug)
                            }
                          >
                            {option.product_name}
                          </Suggestion>
                        ))}
                      </SearchSuggestions>
                    )}
                  </SearchContainer>
                </MobileSearch>
              )}
            </DropSearch> */}
            {/* <Buttons>
              <Button className="mobile">
                <Number className="mobile">{wish?.length}</Number>
                <Link to="/wishlist">
                  <span>
                    <CiHeart />
                  </span>
                </Link>
              </Button>
              <Button className="mobile">
                <Number className="mobile">{cartData?.length}</Number>
                <Link to="/cart">
                  <span>
                    <PiShoppingCartThin />
                  </span>
                </Link>
              </Button>
             
            </Buttons> */}
          </MobileHeader>
          <Slider className={isMobileHeaderActive ? "active" : ""}>
            <MobileTop>
              <Link to="/">
                <ImageContainer>
                  <img src="/storage/general/ks-logo32.png" />
                </ImageContainer>
              </Link>
              <div onClick={toggleMobileHeader}>
                <IoCloseOutline />
              </div>
            </MobileTop>
            <Socials className="mobile">
              <a
                target="_blank"
                href="https://www.facebook.com/myKeralaspecial?mibextid=qi2Omg&rdid=kccxW9NpVX3BFzrU&share_url=https%3A%2F%2Fwww.facebook.com%2Fshare%2FXM1oRDDkvagcQKW9%2F%3Fmibextid%3Dqi2Omg"
              >
                <GrFacebookOption />
              </a>
              <a
                target="_blank"
                href="https://www.instagram.com/keralaspecial/"
              >
                <LiaInstagram />
              </a>
              <a
                target="_blank"
                href="https://www.youtube.com/@keralaspecial5256"
              >
                <AiFillYoutube />
              </a>
              <a
                target="_blank"
                href="https://x.com/mykeralaspecial?t=5jaIdWgLfUhVzxywcQto9g"
              >
                <RiTwitterXLine />
              </a>
            </Socials>
            <Buttons className="mobile">
              {!authenticated ? (
                <>
                  <Link to="/login" onClick={() => props?.SearchFetch()}>
                    {" "}
                    <button className="">LOGIN</button>
                  </Link>
                  <Link to="/register">
                    <button className="">REGISTER</button>
                  </Link>
                </>
              ) : (
                <>
                  <div className="">
                    <Link to="/myorders" onClick={() => props?.SearchFetch()}>
                      {" "}
                      <button className="">MY ORDERS</button>
                    </Link>
                  </div>
                  <div className="" onClick={handleLogout}>
                    <Link to="/" onClick={() => props?.SearchFetch()}>
                      {" "}
                      <button className="">LOGOUT</button>
                    </Link>
                  </div>
                </>
              )}
              {/* <LoginBtn>
                <Link to="/login">Login</Link>
              </LoginBtn>
              <LoginBtn>
                <Link to="/register">Register</Link>
              </LoginBtn> */}
            </Buttons>
            <Nav className="mobile">
              <Link to="/">Home</Link>
              <Link to="/about-us">About Us</Link>
              <Link to="/blog">Blogs</Link>
              <Link to="/contact-us">Contact Us</Link>
            </Nav>
            <P>Copyright</P>
          </Slider>
          <MobileHeaderBottom>
            <Buttons className="bottom">
              <Button onClick={toggleMobileHeader}>
                <GoHome />
              </Button>
              <Button className="mobile" onClick={handleOpenCategory}>
                <span>
                  <RxDashboard />
                </span>
              </Button>
              <Button className="mobile">
                <Number className="mobile">{wish?.length}</Number>
                <Link to={authenticated ? "/wishlist" : "/login"}>
                  <span>
                    <CiHeart />
                  </span>
                </Link>
              </Button>
              <Button className="mobile">
                <Number className="mobile">{cartData?.length}</Number>
                <Link to={authenticated ? "/wishlist" : "/login"}>
                  <span>
                    <PiShoppingCartThin />
                  </span>
                </Link>
              </Button>
              {authenticated ? (
                <Button onClick={handleOpenLoginDropDown}>
                  <div>
                    <span>
                      <LiaUserCircle />
                    </span>
                  </div>
                </Button>
              ) : (
                <Button onClick={handleOpenLoginDropDown}>
                  <CiLogin />
                </Button>
              )}
              {!authenticated ? (
                <LogoutModalMobile
                  className={openLoginDropdown ? "active " : ""}
                >
                  {" "}
                  <div className="btn-wrap">
                    <Link to="/login" onClick={() => props?.SearchFetch()}>
                      {" "}
                      <button className="login-btn">LOGIN</button>
                    </Link>
                    <Link to="/register">
                      <button className="login-btn">REGISTER</button>
                    </Link>
                  </div>
                </LogoutModalMobile>
              ) : (
                <LogoutModalMobile
                  className={openLoginDropdown ? "active " : ""}
                >
                  <div className="btn-wrap">
                    <Link to="/myorders" onClick={() => props?.SearchFetch()}>
                      {" "}
                      <button className="login-btn">MY ORDERS</button>
                    </Link>
                  </div>
                  <div className="btn-wrap" onClick={handleLogout}>
                    <Link to="/" onClick={() => props?.SearchFetch()}>
                      {" "}
                      <button className="login-btn">LOGOUT</button>
                    </Link>
                  </div>
                </LogoutModalMobile>
              )}
              <CategoriesModal className={isCategoriesVisible ? "active" : ""}>
                <Items className="mobile">
                  {data.slice(0, -1).map((item, i) => (
                    <Item
                      // style={{
                      //   backgroundColor:
                      //     selectedCategory === item.category_id && subcategory
                      //       ? "#e1f0da"
                      //       : "transparent",
                      // }}
                      key={i}
                      // onMouseEnter={() => handleSubCategory(item.category_id)}
                      // onClick={() => setSubcategory(false)}
                      onClick={() => {
                        handleCategoryMain(item.category_slug);
                      }}
                    >
                      <ItemDiv>
                        <>
                          <div>
                            <img
                              src={categoryurl + item.logo}
                              width={30}
                              height={30}
                            />{" "}
                          </div>
                          <div>{item.category_name}</div>
                        </>
                        {/* <Arrow>
                          <BsChevronDown />
                        </Arrow> */}
                      </ItemDiv>
                    </Item>
                  ))}
                </Items>
              </CategoriesModal>
            </Buttons>
          </MobileHeaderBottom>
        </>
      )}
    </>
  );
}

export default HeaderMainNew;
const Header = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  @media screen and (max-width: 1080px) {
    display: none;
  }
`;
const Container = styled.div`
  display: grid;
  grid-template-columns: 0.2fr 1.8fr 0.5fr;
  gap: 20px;
  align-items: center;
`;
const Top = styled.div`
  padding: 16px 40px;
  background-color: #084c27;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const Left = styled.div``;
const Right = styled.div`
  a {
    background-color: #fff;
    color: #084c27;
    font-weight: 600;
    border-radius: 50px;
    padding: 10px 20px;
    font-size: 14px;
    font-family: "Jost", serif;
  }
`;
const Socials = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;

  a {
    flex-shrink: 0;

    background-color: #fff;

    border-radius: 50%;
    padding: 4px;
  }
  svg {
    color: #084c27;
    font-size: 15px;
  }

  /* mobile */
  &.mobile {
    justify-content: center;
    margin: 20px 0;
    a {
      background-color: transparent;
    }
    svg {
      font-size: 33px;
      padding: 5px;
      background-color: #084c27;
      color: #fff;
      border-radius: 10px;
    }
  }
`;
const Main = styled.div`
  background-color: #fff;
`;
const Section = styled.section`
  justify-self: end;
  /* .top-btns {
    @media screen and (max-width: 1080px) {
      display: none;
    }
  } */
`;
const ImageContainer = styled.div`
  height: 70px;
  width: 125px;
  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    display: block;
  }
  @media screen and (max-width: 540px) {
    height: 60px;
    width: 115px;
  }
`;
const Nav = styled.nav`
  display: flex;
  gap: 20px;
  align-items: center;
  justify-self: center;
  justify-content: space-between;
  width: 100%;
  a {
    font-family: "Jost", serif;
    font-weight: 500;
    color: #084c27;
    font-size: 18px;

    &:hover {
      transition: all 0.3s ease;
      font-size: 20px;
      font-weight: 500;
    }
  }

  /* mobile */
  &.mobile {
    display: grid;
    justify-content: start;
    a {
      width: 100%;
      margin-bottom: 10px;
      padding-bottom: 5px;
      /* border-bottom: 1px solid #9c9c9c; */
    }
  }
`;
const NavInner = styled.div`
  display: flex;
  gap: 35px;
  align-items: center;
  padding-left: 50px;
`;
const SearchContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #9c9c9c;
  border-radius: 7px;
  padding: 5px 15px;
  margin-left: 20px;
  width: 100%;
  @media screen and (max-width: 540px) {
    margin-left: 0px;
  }
`;
const SearchSuggestions = styled.ul`
  position: absolute;
  top: 62%;
  z-index: 100;
  background-color: #084c27;
  left: 64%;
  transform: translateX(-50%);
  border-radius: 10px;
  &.mobile {
    top: 80%;
    left: 80%;
    width: 35%;

    @media screen and (max-width: 540px) {
      top: 100%;
      left: 52%;
      width: 90%;
    }
  }
  &.mobile-drop {
    top: 103%;
    left: 50%;
    width: 100%;
    max-height: 300px;
  }

  max-height: 350px;
  overflow-y: scroll;
`;
const Suggestion = styled.li`
  margin-left: 0;
  border-radius: 10px;
  color: #fff;
  font-size: 15px;
  cursor: pointer;
  padding: 10px;
  &:hover {
    color: #fc9800;
  }
`;
const Input = styled.input`
  border: none;
  font-family: "Jost", serif;
  outline: none;
  &::placeholder {
    color: #9c9c9c;
    font-family: "Jost", serif;
  }
  &:focus {
    outline: none;
    border: none;
  }
  &.mobile-search {
    padding: 0;
    &::placeholder {
      padding-left: 0;
      padding-bottom: 0;
    }
  }
`;
const Buttons = styled.div`
  display: flex;
  align-items: end;
  gap: 25px;

  &.mobile {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
    align-items: center;
    margin: 40px 0;
    button {
      padding: 8px 20px;
      border: 2px solid #084c27;
      border-radius: 8px;
      background-color: transparent;
    }
  }

  &.bottom {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
`;
const LoginBtn = styled.button`
  border: 2px solid #084c27;
  border-radius: 5px;
  color: #084c27;
  padding: 5px 20px;
  background-color: transparent;
  font-family: "Jost", serif;
  font-size: 18px;
`;
const Button = styled.button`
  display: grid;
  justify-items: start;
  flex-shrink: 0;
  border: none;
  outline: none;
  background-color: transparent;
  a {
    font-family: "Jost", serif;
    color: #000;
    &:hover {
      transition: all 0.3s ease;
      font-size: 16px;
      font-weight: 500;
    }
  }
  svg {
    font-size: 20px;
  }

  &.notification-style {
    position: relative;
  }
  &.user {
    display: flex;
    align-items: center;
    /* justify-items: center;
    font-size: 13px; */
  }

  /* mobile */
  &.mobile {
    position: relative;
  }
`;
const Number = styled.p`
  height: 22px;
  width: 22px;
  border-radius: 50%;
  background-color: #ff0000;
  color: #fff;
  font-weight: 400;
  font-size: 13px;
  font-family: "Jost", serif;
  display: grid;
  place-items: center;
  position: absolute;
  left: -10px;
  top: -10px;
  margin-bottom: 0;
  /* mobile */
  &.mobile {
    position: absolute;
    right: -10px;
    top: -10px;
    height: unset;
    width: unset;
    height: 22px;
    width: 22px;
    font-size: 13px;
    margin-bottom: 0;
  }
`;
// mobile

const MobileHeader = styled.div`
  display: none;
  @media screen and (max-width: 1080px) {
    background-color: #fff;
    padding: 10px 40px;
    display: grid;
    grid-template-columns: 2fr 1fr;

    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;
  }
  @media screen and (max-width: 540px) {
    grid-template-columns: 0.2fr 1fr;
    padding: 10px;
    gap: 30px;
  }
`;
const MobileHeaderBottom = styled.div`
  display: none;
  @media screen and (max-width: 1080px) {
    background-color: #fff;
    padding: 20px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 10;
  }
`;
const Slider = styled.div`
  position: fixed;
  left: -100%;
  top: 0;
  padding: 10px 20px;
  height: 100%;
  background-color: #f8f8f8;
  transition: all 0.5s linear;
  z-index: 11;
  &.active {
    left: 0;
  }
`;
const MobileTop = styled.div`
  display: grid;
  grid-template-columns: 1fr 0.1fr;
  gap: 20px;
  align-items: center;

  svg {
    font-size: 20px;
    background-color: #d5dded;
    padding: 5px;
    border-radius: 50%;
  }
`;
const P = styled.p`
  font-family: "Jost", serif;
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
`;
const ItemContainer = styled.div`
  padding: 10px 0;
  background-color: #ffcf8c;
`;
const Items = styled.div`
  display: flex;
  justify-content: center;
  gap: 40px;
  &.mobile {
    flex-direction: column;
  }
`;
const Dropdown = styled.div`
  z-index: 99;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: calc(100% + 0px);
  border-radius: 10px;

  box-shadow: 1px 1px 14px #4242426f;
  background-color: #fff;
  padding: 10px;

  transition: all 0.3s ease;
  display: none;
  /* opacity: 0; */

  li {
    font-size: 15px;
    color: #000;
    min-width: max-content;
    list-style-type: none;
    padding: 8px 10px;
    cursor: pointer;
    &:hover {
      color: #fc9800;
    }
  }
`;
const Item = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  transform: all 0.5s ease;

  &:hover ${Dropdown} {
    display: grid;
    /* opacity: 1; */
  }
`;
const Icon = styled.div``;
const Text = styled.p`
  font-size: 16px;
  color: #000;
  font-family: "Jost", serif;
`;
const Arrow = styled.div`
  svg {
    font-size: 16px;
    color: #000;
  }
`;
const LoginButton = styled.div`
  font-size: 20px;
  text-transform: capitalize;
`;
const ItemDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;
const LogoutModal = styled.div`
  position: absolute;
  right: -50%;
  top: 130px;

  background-color: #fff;
  padding: 10px;
  transition: 0.3s ease;
  z-index: 2;
  &.active {
    right: 50px;
  }
`;
const LogoutModalMobile = styled.div`
  position: absolute;
  right: -100%;
  bottom: 62px;
  z-index: 2;
  background-color: #fff;
  padding: 10px;
  transition: 0.3s ease;
  &.active {
    right: 0px;
  }

  button {
    margin-bottom: 0;
  }
  display: grid;
  gap: 20px;
`;
const CategoriesModal = styled.div`
  position: absolute;
  bottom: -800%;
  left: 0;
  width: 100%;
  padding: 20px;
  background-color: #fff;
  transition: all 0.4s ease;
  &.active {
    bottom: 62px;
  }
`;
const DropSearch = styled.div`
  display: none;
  @media screen and (max-width: 540px) {
    display: block;
    justify-self: end;
  }
`;
const MobileSearch = styled.div`
  @media screen and (max-width: 540px) {
    border-radius: 10px;
    background-color: #fff;
    justify-self: end;
    position: fixed;
    top: 80px;
    left: 50%;
    transform: translateX(-50%);
  }
`;
