import React, { useEffect, useState } from "react";
import { backend, blogurl, categoryurl, producturl } from "../../../../path";
import LoaderMain from "../../../layout/header/loader_sub";
import { Link } from "react-router-dom";
import styled from "styled-components";
function BlogUser(props) {
  const [data, setData] = useState([]);
  const [catData, setCatData] = useState([]);
  const [error, setError] = useState();
  const [loading, setLoading] = useState();
  const [authenticated, setAuthenticated] = useState(
    JSON.parse(localStorage.getItem("userdata"))
  );
  const fetchData = () => {
    // Assuming setData, setError, and setLoading are state functions from useState
    setLoading(true);

    fetch(backend + "getblog", {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setData(data);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchCatData = () => {
    // Assuming setData, setError, and setLoading are state functions from useState
    setLoading(true);

    fetch(backend + "getcategorywithsubcategory", {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setCatData(data);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
    fetchCatData();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    // <div className="contact">
    //   <div className="page-header breadcrumb-wrap">
    //     <div className="container">
    //       <div className="breadcrumb">
    //         <a href="/" className="breadcrumb-item d-inline-block" title="Home"> Home </a>
    //         <span></span>
    //         <div className="breadcrumb-item d-inline-block active"> Blog </div>
    //       </div>
    //     </div>
    //   </div>
    //   {loading &&
    //     <LoaderMain />
    //   }
    //   <section className="banners py-5">
    //     <div className="container">
    //       <div className="col-lg-12 col-md-12">
    //         <a className="btn btn-lg mb-2 w-100"
    //           style={{
    //             boxShadow: 'rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset', fontSize: '18px',
    //             backdropFilter: 'inherit',
    //             backgroundBlendMode: 'color-burn',
    //             backgroundColor: '#ADD899',
    //             fontWeight: 'bolder',
    //             color: '#fff',
    //           }}
    //         >
    //           KeralaSpecial Blogs
    //         </a>
    //       </div>
    //       <div className="row justify-content-center my-3">
    //         {data?.map((blog) => (
    //           <div key={blog.blog_id} className="col-lg-4 col-md-6">
    //             <div
    //               className="banner-img wow animate__animated animate__fadeInUp "
    //               data-wow-delay="0.2"
    //               style={{
    //                 boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px'
    //               }}
    //             >
    //               {/* <h4>
    //                       {blog?.title}
    //                     </h4> */}
    //               <a className="btn btn-lg p-3 w-100 py-3"

    //                 style={{
    //                   fontSize: '16px', backgroundColor: '#A67B5B',
    //                   boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px'
    //                 }}
    //               >
    //                 <div className="py-3"> {blog?.title}</div>

    //                 <a href={`/blog/${blog?.blog_id}`} target="_blank">

    //                   <picture>
    //                     <source
    //                       srcset={blogurl + blog?.thumbnail}
    //                       media="(min-width: 1200px)"
    //                     />
    //                     <source
    //                       srcset={blogurl + blog?.thumbnail}
    //                       media="(min-width: 768px)"
    //                     />
    //                     <source
    //                       srcset={blogurl + blog?.thumbnail}
    //                       media="(max-width: 767px)"
    //                     />
    //                     <img
    //                       src={blogurl + blog?.thumbnail}
    //                       alt=""
    //                       className="page_speed_2025002599"
    //                       style={{ width: '350px', height: '223px' }}
    //                     />
    //                   </picture>
    //                 </a>
    //                 <div className="banner-button">
    //                   <a href={`/blog/${blog?.blog_id}`} className="btn btn-xs"
    //                     style={{ backgroundColor: '#A67B5B' }}

    //                   >
    //                     Read More <i className="fi-rs-arrow-small-right"></i>
    //                   </a>
    //                 </div>
    //               </a>
    //             </div>
    //           </div>
    //         ))}

    //       </div>
    //     </div>
    //   </section>

    // </div>

    <main class="main" id="main-section">
      <div className="page-header breadcrumb-wrap">
        <div className="container">
          <div className="breadcrumb">
            <a href="/" className="breadcrumb-item d-inline-block" title="Home">
              {" "}
              Home{" "}
            </a>
            <span></span>
            <div className="breadcrumb-item d-inline-block active"> Blogs</div>
          </div>
        </div>
      </div>
      <div className="wrapper">
        <Header>
          <H2>Blogs</H2>
        </Header>

        <div>
          <Container>
            <div className="left">
              <Blogs>
                {data?.map((blog) => (
                  <Blog key={blog.blog_id}>
                    <Link to={`/blog/${blog?.blog_id}`}>
                      <img
                        src={blogurl + blog?.thumbnail}
                        alt="4 Expert Tips On How To Choose The Right Men’s Wallet"
                        style={{ height: "203px", width: "100%" }}
                      />
                    </Link>

                    <Bottom>
                      <p>
                        <Link class="text-muted" to={`/blog/${blog?.blog_id}`}>
                          KeralaSpecial Blogs
                        </Link>
                      </p>
                      <h4>
                        <Link to={`/blog/${blog?.blog_id}`}>{blog?.title}</Link>
                      </h4>
                      {/* <div class="entry-meta font-xs color-grey mt-10 pb-10">
                                    <div>
                                        <span class="post-on mr-10">15 Sep 2024</span>
                                        <span class="hit-count has-dot mr-10">2,207 Views</span>
                                    </div>
                                </div> */}
                    </Bottom>
                  </Blog>
                ))}
              </Blogs>
            </div>

            <Categories className="left">
              <Category class="">
                <h5 class="section-title style-1 mb-30">Categories</h5>
                <ul>
                  {catData.map((item, i) => (
                    <Li key={i}>
                      <Link to={`/category/${item.category_slug}`}>
                        <img
                          data-bb-lazy="true"
                          loading="lazy"
                          src={categoryurl + item.logo}
                          alt={item.category_name}
                        ></img>
                        {item.category_name}
                      </Link>
                      <span class="count">{item.subcategory.length}</span>
                    </Li>
                  ))}
                </ul>
              </Category>
              {authenticated?.usertype == 1 && (
                <Category class="tags">
                  <h5 class="section-title style-1 mb-30">Popular Tags</h5>
                  <ul class="tags-list">
                    <Li className="no-bg">
                      <a onClick={() => props?.setSearch("Kannadi")}>
                        <i class="fi-rs-cross mr-10"></i>
                        Kannadi
                      </a>
                    </Li>
                    <Li className="no-bg">
                      <a onClick={() => props?.setSearch("Mundu")}>
                        <i class="fi-rs-cross mr-10"></i>
                        Mundu
                      </a>
                    </Li>
                    <Li className="no-bg">
                      <a onClick={() => props?.setSearch("Amadapetti")}>
                        <i class="fi-rs-cross mr-10"></i>
                        Amadapetti
                      </a>
                    </Li>
                    <Li className="no-bg">
                      <a onClick={() => props?.setSearch("Balaramapuram")}>
                        <i class="fi-rs-cross mr-10"></i>
                        Balaramapuram
                      </a>
                    </Li>
                  </ul>
                </Category>
              )}
            </Categories>
          </Container>

          {loading && <LoaderMain />}
        </div>
      </div>
    </main>
  );
}

export default BlogUser;
const Header = styled.div``;
const H2 = styled.h2`
  font-weight: 500;
  padding: 20px 0;
  @media screen and (max-width: 640px) {
    font-size: 25px;
  }
`;
const Blogs = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  @media screen and (max-width: 840px) {
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (max-width: 540px) {
    grid-template-columns: 1fr;
  }
`;
const Blog = styled.div`
  border: 1px solid #00000028;
  border-radius: 10px;
  overflow: hidden;
`;
const Bottom = styled.div`
  padding: 20px 10px;
  h4 {
    a {
      color: #212121;
      font-weight: 600;
      font-size: 23px;
    }
  }
`;
const Container = styled.div`
  display: grid;
  grid-template-columns: 2fr 0.8fr;
  gap: 30px;
  @media screen and (max-width: 1180px) {
    grid-template-columns: 1fr;
  }
`;
const Categories = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 30px;
  @media screen and (max-width: 1180px) {
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (max-width: 480px) {
    grid-template-columns: 1fr;
  }
`;
const Category = styled.div`
  padding: 20px 0;
`;
const Li = styled.li`
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  a {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  &:hover {
    background-color: #094c27;
    a,
    span {
      color: #fff;
    }
  }
  &.no-bg {
    &:hover {
      background-color: unset;
      a,
      span {
        color: unset;
      }
    }
  }
  /* span {
    border: 1px solid #094c27;
    padding: 3px 12px;
    border-radius: 50%;
  } */
`;
