import React, { useEffect } from "react";
import abouImage from "./about.jpeg";
import ScrollToTop from "../../../components/whatsapp connect";
import "./About.css";
import styled from "styled-components";
import Marquee from "react-fast-marquee";
function AboutUsNew() {
  useEffect(() => {
    // Scroll to the top of the page whenever a new page is rendered
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <main>
        <div className="page-header breadcrumb-wrap">
          <div className="container">
            <div className="breadcrumb">
              <a
                href="/"
                className="breadcrumb-item d-inline-block"
                title="Home"
              >
                {" "}
                Home{" "}
              </a>
              <span></span>
              <div className="breadcrumb-item d-inline-block active">
                {" "}
                About Us
              </div>
            </div>
          </div>
        </div>
        <div className="wrapper">
          <Container className="about">
            <Left className="about">
              <ImageContainer className="about">
                <img src="/storage/general/about.png" alt="about-banner" />
              </ImageContainer>
            </Left>
            <Right>
              <H2>About Us</H2>
              <H5>Welcome to Keralaspecial.in</H5>
              <P>
                Kerala, fondly known as "God's Own Country," is a land of
                breathtaking beauty, vibrant traditions, and rich cultural
                heritage. From its tranquil beaches to its lush greenery and
                exquisite craftsmanship, Kerala offers an unforgettable
                experience that lingers in the heart. Yet, recreating that magic
                in daily life can often feel out of reach.
                <br /> <br />
                At KeralaSpecial.in, we bridge this gap, bringing the true
                essence of Kerala straight to your doorstep.
              </P>
              <H5>Discover the Magic of Kerala</H5>
              <P>
                <b>Curated Treasures:</b>
                Our dedicated team of artists, academics, and industry experts
                meticulously handpicks the finest products Kerala has to offer.
                From intricate traditional handicrafts to authentic Ayurvedic
                wellness products, we ensure every item tells a story of
                heritage and excellence.
              </P>
            </Right>
          </Container>
          <P>
            <b>Empowering Local Artisans:</b>
            Each purchase you make helps sustain Kerala's skilled artisans,
            weavers, and craftsmen. By supporting their work, you contribute to
            preserving centuries-old traditions and uplifting local communities.
          </P>
          <P>
            <b>Uncompromising Quality:</b> At KeralaSpecial.in, quality is our
            promise. Every product undergoes stringent quality checks, ensuring
            that you receive only the best Kerala has to offer.
          </P>
          <P>
            Whether you're looking for a piece of Kerala to brighten your home,
            a meaningful gift for a loved one, or an authentic connection to
            this enchanting land, KeralaSpecial.in is your trusted partner in
            celebrating the spirit of "God's Own Country."
            <br />
            <br />
            Start your journey with us and bring a touch of Kerala’s magic into
            your life today!
          </P>
          <H2>Experience Kerala: Enchantment Delivered</H2>
          <P>
            Kerala, fondly known as "God's Own Country," offers an unforgettable
            experience. From its serene beaches to its vibrant culture, Kerala
            leaves a lasting impression. But recreating that magic can be
            difficult. At Keralaspecial.in, we bridge the gap. We bring the
            essence of Kerala straight to your doorstep.
          </P>
        </div>
        <Launch>
          <div className="wrapper">
            <Container className="launch">
              <Left>
                <H2 className="launch">the grand launch event </H2>
                <P>
                  KeralaSpecial.in was inaugurated with great enthusiasm and
                  grandeur by the eminent Shri Kanayi Kunhiraman, a celebrated
                  sculptor and an ardent advocate for Kerala’s rich cultural
                  heritage. In his inaugural address, Shri Kanayi shared his
                  heartfelt vision for KeralaSpecial.in, aspiring for it to
                  become a dynamic online platform that celebrates and preserves
                  Kerala's exquisite arts, crafts, and traditions.
                </P>
                <H5>A Gathering of Luminaries</H5>
                <P>
                  The event was graced by some of Kerala’s most distinguished
                  personalities, who lent their support and encouragement to
                  this ambitious endeavor. Leading the dignitaries was Shri
                  Kanayi Kunhiraman, whose passion for culture and art deeply
                  resonates with KeralaSpecial.in's mission.
                </P>
              </Left>
              <Right className="launch">
                <ImageContainer className="launch">
                  <img src="/storage/general/about2.png" alt="about-banner" />
                </ImageContainer>
              </Right>
            </Container>
            <H5>Also in attendance were:</H5>
            <P>
              <b>Dr. P. K. Madhavan Nair,</b> former member of the Kerala Public
              Service Commission, who brought his wisdom and experience to the
              occasion.
              <br />
              <br />
              <b>Shri P. K. Venugopal,</b> former Chairman of the Trivandrum
              Development Authority, whose leadership in development and
              governance inspired the initiative’s forward-looking vision.{" "}
              <br />
              <br />
            </P>
            <H5>Guided by Visionary Leaders</H5>
            <P>
              The launch was further illuminated by the presence of
              KeralaSpecial.in’s visionary directors:
              <br />
              <br />
              <b>Dr. Vijayakumaran Nair,</b> a seasoned academic and strategist.
              <br />
              <br />
              <b>Dr. N K  Mohan Das,</b> whose passion and expertise drive the
              venture forward.
              <br />
              <br />
              <b>Mr. Shiju Lukose,</b> a technology expert committed to building
              a robust digital presence for KeralaSpecial.in.
              <br />
              <br />
              Their collective leadership and unwavering commitment are pivotal
              to KeralaSpecial.in’s journey of bringing the magic of Kerala to a
              global audience.
            </P>
          </div>
        </Launch>
        <div className="wrapper">
          <Featured>
            <H2>Featured In</H2>
            <MarqueeContainer>
              <Marquee pauseOnHover={true}>
                <IconContainer>
                  <img src="/storage/general/featured1.png" alt="featured-in" />
                </IconContainer>
                <IconContainer>
                  <img src="/storage/general/featured2.png" alt="featured-in" />
                </IconContainer>
                <IconContainer>
                  <img src="/storage/general/featured3.png" alt="featured-in" />
                </IconContainer>
                <IconContainer>
                  <img src="/storage/general/featured4.png" alt="featured-in" />
                </IconContainer>
                <IconContainer>
                  <img src="/storage/general/featured5.png" alt="featured-in" />
                </IconContainer>
              </Marquee>
            </MarqueeContainer>
          </Featured>
          <Essence>
            <H2>Discover the Essence of Kerala with KeralaSpecial .in</H2>
            <P>
              Kerala offers an experience like no other. <br />
              <br />
              At KeralaSpecial.in, we bring the spirit of Kerala closer to you.
              Whether you're near or far, we ensure that the authentic charm of
              this enchanting land reaches your doorstep.
            </P>
            <H5>Why Choose KeralaSpecial.in ?</H5>
            <H5>Unearth Exquisite Products </H5>
            <P>
              Our passionate team, comprising artists, academics, and industry
              experts, meticulously curates the very best Kerala has to offer.
              From timeless traditional handicrafts to holistic Ayurvedic
              products, every item reflects the soul of Kerala’s rich cultural
              heritage.
            </P>
            <H5>Support Local Artisans</H5>
            <P>
              Every purchase you make contributes to preserving Kerala’s age-old
              traditions. By supporting talented artisans, weavers, and
              craftsmen, you’re empowering local communities and helping to
              sustain their livelihoods for generations to come.
            </P>
            <H5>Experience Unmatched Quality</H5>
            <P>
              At KeralaSpecial.in, quality is our promise. Each product
              undergoes rigorous checks to ensure it meets the highest
              standards, delivering authenticity and excellence to your home.{" "}
              <br /> With KeralaSpecial.in, you’re not just shopping—you’re
              embracing the heritage, artistry, and spirit of Kerala. Let us
              bring a piece of "God's Own Country" into your life, wherever you
              are.
            </P>
          </Essence>
        </div>
      </main>
    </>
  );
}

export default AboutUsNew;
const Container = styled.div`
  display: grid;
  gap: 50px;

  &.about {
    padding-top: 40px;
    grid-template-columns: 1.5fr 1fr;
    @media screen and (max-width: 1080px) {
      grid-template-columns: 1fr;
      gap: 20px;
    }
  }
  &.launch {
    grid-template-columns: 1.4fr 1fr;
    @media screen and (max-width: 1080px) {
      grid-template-columns: 1fr;
      gap: 20px;
    }
  }
`;
const H2 = styled.h2`
  font-weight: 500;
  margin: 25px 0;
  text-transform: uppercase;
  &.launch {
    margin-top: 0;
  }

  @media screen and (max-width: 768px) {
    font-size: 25px;
    margin: 12px 0;
  }
`;
const H5 = styled.h5`
  padding: 15px 0;
  font-weight: 500;
  @media screen and (max-width: 768px) {
    font-size: 18px;
  }
`;
const H6 = styled.h6`
  font-weight: 500;
`;
const P = styled.p`
  padding: 17px 0;
  line-height: 1.5;
  color: #000;
  @media screen and (max-width: 768px) {
    font-size: 15px;
  }
`;
const Left = styled.div`
  &.about {
    @media screen and (max-width: 1080px) {
      justify-self: center;
    }
  }
`;
const Right = styled.div`
  &.launch {
    @media screen and (max-width: 1080px) {
      justify-self: center;
    }
  }
`;
const ImageContainer = styled.div`
  &.about {
    height: 600px;
    width: 100%;

    @media screen and (max-width: 1080px) {
      height: 400px;
    }
    @media screen and (max-width: 480px) {
      height: 285px;
    }
  }
  &.launch {
    height: 400px;
    padding: 10px;
    background-color: #fff;
    @media screen and (max-width: 540px) {
      height: 100%;
      width: 100%;
    }
  }
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    display: block;
  }
`;
const Launch = styled.div`
  background-color: #ffedd4;
  padding: 40px 0;
`;

const Featured = styled.div`
  padding: 40px 0;
`;
const Essence = styled.div`
  padding: 40px 0;
`;
const MarqueeContainer = styled.div`
  border: 1px solid black;
  padding: 10px;
`;
const IconContainer = styled.div`
  height: 150px;

  img {
    height: 100%;
    width: 100%;
    display: block;
    object-fit: contain;
  }
`;
