import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { backend, producturl, vendorurl } from "../../../../path";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import ScrollToTop from "../../../components/whatsapp connect";
import Rating from "@mui/material/Rating";
import Avatar from "../../../components/avatar";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
// import ReactImageZoom from "react-image-zoom";
import { Helmet } from "react-helmet";
import ImageCarousel from "../../../components/small screen product swipe";
import LoaderMain from "../../../layout/header/loader_sub";
import BuyNow from "../direct_buy";
import styled from "styled-components";
import { SlLocationPin } from "react-icons/sl";
import StarIcon from "@mui/icons-material/Star";
import { BsCart3 } from "react-icons/bs";
import { IoMdHeartEmpty } from "react-icons/io";

import {
  HiOutlineArrowLeft,
  HiOutlineArrowRight,
  HiOutlinePlus,
} from "react-icons/hi2";
import { AiOutlineMinus } from "react-icons/ai";

import { PiShoppingCartThin } from "react-icons/pi";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { CiHeart } from "react-icons/ci";
import { VscHeartFilled } from "react-icons/vsc";
import TopSellingComponent from "../../topselling/Index";
import { trimDescriptionToTen } from "../../../../utils/trimDescription";

function ProductFullWidthNew(props) {
  const [isHovered, setIsHovered] = useState("0");
  const [loading, setLoading] = useState();
  const [activeStep, setActiveStep] = useState(false);
  const [error, setError] = useState();
  const [data, setData] = useState([]);
  const [topSellingData, setTopSellingData] = useState([]);
  const [grouPData, setGroupData] = useState([]);
  const [count, setCount] = useState(1);
  const [expanded, setExpanded] = useState(false);
  const [value, setValue] = useState(2);
  const [rating, setRating] = useState("");
  const [review, setReview] = useState("");
  const [starShow, setStarShow] = useState(0);
  const [imgLoading, setImgLoading] = useState();
  const [displayData, setDisplayData] = useState("description");
  const sliderRef = useRef(null);
  const [hover, setHover] = React.useState(-1);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    waitForAnimate: false,
  };
  const Topsettings = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
    ],
  };
  const handlePrev = () => {
    sliderRef.current.slickPrev();
  };
  const handleNext = () => {
    sliderRef.current.slickNext();
  };
  console.log("image data", producturl + data.thumbnail);

  const [mainImage, setMainImage] = useState("");

  const handleImageLoad = () => {
    setImgLoading(false);
  };

  const { id } = useParams();
  const handleReadMoreClick = () => {
    document
      .getElementById("description-section")
      .scrollIntoView({ behavior: "smooth" });
  };
  const handleReviewClick = () => {
    document
      .getElementById("description-section")
      .scrollIntoView({ behavior: "smooth" });
    setIsHovered("3");
  };

  const toggleDescription = () => {
    setExpanded(!expanded);
  };
  const navigate = useNavigate();
  const [authenticated, setAuthenticated] = useState(
    JSON.parse(localStorage.getItem("userdata"))
  );

  const handleAddCart = async (id) => {
    if (authenticated === null) {
      navigate(`/login`);
      return;
    }

    const formData = {
      status: 1,
      quantity: count,
      product_id: id,
    };

    try {
      const response = await fetch(backend + "addtocartuserside", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authenticated?.access_token}`,
        },
        body: JSON.stringify(formData),
      });

      if (response.status === 200) {
        toast.success("Item added to cart", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        props.setCartChange(!props.cartChange);
        await new Promise((resolve) => setTimeout(resolve, 3000));
        // navigate("/cart");
      } else if (response.status === 409) {
        toast.error("Conflict occurred. Please try again later.", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (response.status === 401) {
        toast.error("Item already exits in the cart", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
      }
    } catch (error) {}
  };
  const handleBuyNow = async (id) => {
    if (authenticated === null) {
      navigate(`/login`);
      return;
    }
    setActiveStep(true);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const fetchData = () => {
    setLoading(true);

    fetch(backend + `getproductbyiduserside/${id}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authenticated?.access_token}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setData(data[0]);
        setStarShow(data[0]?.average_stars);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const fetchTopSellingData = () => {
    setLoading(true);

    fetch(backend + "getallspecialproduts", {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setTopSellingData(data.getTopSelling);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const fetchGroupData = (id) => {
    fetch(backend + `getsimilarproducts/${id}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authenticated?.access_token}`,
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        console.log(data);
        setGroupData(data);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {});
  };
  useEffect(() => {
    fetchTopSellingData();
  }, []);
  const decodeHTML = (html) => {
    const txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  };

  useEffect(() => {
    if (data?.group_id) {
      fetchGroupData(data.group_id);
    }
  }, [data?.group_id]);
  console.log(data.group_id);

  const handleIncrement = () => {
    if (count === 1) {
      setCount(2);
    } else {
      setCount((prevCount) => prevCount + 1);
    }
  };

  const handleDecrement = () => {
    if (count === 1) {
      setCount(1);
    } else {
      setCount((prevCount) => prevCount - 1);
    }
  };

  const handleProductRoute = (productId) => {
    // sessionStorage.setItem("productId", productId);
    navigate(`/product/${productId}`);
    window.scrollTo(0, 0);
  };
  // Ensure data.images is in the correct format
  const imagesArray = data.images
    ? Array.isArray(data.images)
      ? data.images
      : JSON.parse(data.images.replace(/";/g, '"'))
    : [];

  // Iterate over the images array to extract image names
  const imageNames = imagesArray.map((image) => {
    // Extracting the filename from the image path
    const imageName = image.substring(image.lastIndexOf("/") + 1);
    return imageName;
  });

  const [selectedImage, setSelectedImage] = useState(
    imageNames.length > 0 ? imageNames[0] : ""
  );

  // const imagesArray = data.images ? JSON.parse(data.images) : [];
  const handleClick = (image) => {
    setSelectedImage(image);
  };

  const handleAddToWishList = async (id) => {
    if (authenticated === null) {
      navigate(`/login`);
      return;
    }

    const newformData = {
      status: 0,
      product_id: id,
    };

    try {
      const response = await fetch(backend + "addtowishlistuserside", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${authenticated?.access_token}`,
        },
        body: JSON.stringify(newformData),
      });

      if (response.status === 200) {
        toast.success("Item added to wishlist", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        props.setWishChange(!props.wishChange);
        await new Promise((resolve) => setTimeout(resolve, 3000));
      } else if (response.status === 409) {
        toast.error("Conflict occurred. Please try again later.", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else if (response.status === 401) {
        toast.error("Item already exits in the wishlist", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error("Something went wrong. Please try again later.", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {}
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (rating === "") {
      toast.error("Add a rating", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    let formData = {
      product_id: data?.product_id,
      stars: rating,
      comment: review, // Assuming review is the comment text
    };

    fetch(backend + "addcomment", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${authenticated?.access_token}`,
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (!response.ok) {
          console.error(
            "Failed to register:",
            response.status,
            response.statusText
          );

          // Check for conflict status (HTTP 409)
          if (response.status === 401) {
            toast.error("Login To Add a Review", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            toast.error("Something Went Wrong", {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }

          return;
        }

        response.json().then(() => {
          setRating("");
          setReview("");
          fetchData();
          toast.success("Review Added", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        });
      })
      .catch((error) => {
        toast.error("Something Went Wrong", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  const imageSrc = selectedImage
    ? producturl + selectedImage
    : producturl + data?.thumbnail;

  const propsImg = {
    img: imageSrc, // Image source
    zoomPosition: "original", // Position of the zoomed image
    scale: 1.5, // Scale of the zoomed image
  };

  useEffect(() => {
    if (activeStep) {
      window.location.reload();
      fetchData();
      setActiveStep(false);
      setSelectedImage();
    } else {
      fetchData();
      setActiveStep(false);
      setSelectedImage();
    }
  }, [id]);

  //function to change the details to be displayed below
  const handleSetDisplayData = (data) => {
    setDisplayData(data);
  };

  function getImageUrl(data, index) {
    // Check if data is a valid string
    if (typeof data !== "string" || !data.trim()) {
      console.error("Invalid data provided:", data);
      return null; // Return null or an appropriate default value
    }

    try {
      // Use a regular expression to extract all strings inside double quotes
      let matches = data
        .match(/"([^"]*)"/g)
        ?.map((item) => item.replace(/"/g, ""));

      // Check if matches exist and get the first value
      if (matches && matches.length > 0) {
        let firstImage = matches[index];
        // Join the base URL with the first image filename
        return `${producturl}/${firstImage}`;
      } else {
        console.error("No matches found in data:", data);
        return null;
      }
    } catch (error) {
      console.error("Error processing data:", error);
      return null;
    }
  }
  console.log(topSellingData && topSellingData);

  console.log("data : ", data);
  return (
    <>
      <Helmet>
        <title>{data?.product_seo}</title>
        <meta name="description" content={data?.product_meta_description} />
        <meta name="keywords" content={data?.product_meta_keyword} />
      </Helmet>
      <ToastContainer />
      {!activeStep ? (
        <div className="single-product">
          <main className="wrapper">
            <Product>
              <ProductLeft>
                <ProductLeftInner>
                  <MainImage>
                    <Zoom>
                      <img
                        src={
                          mainImage === ""
                            ? producturl + data.thumbnail
                            : mainImage
                        }
                      />
                    </Zoom>
                  </MainImage>

                  <SubImage
                    onClick={() => setMainImage(producturl + data.thumbnail2)}
                  >
                    <Zoom>
                      <img
                        src={producturl + data.thumbnail2}
                        alt={data.product_name}
                      />
                    </Zoom>
                  </SubImage>
                  <SubImage
                    onClick={() => setMainImage(getImageUrl(data.images, 1))}
                  >
                    <Zoom>
                      <img
                        src={getImageUrl(data.images, 1)}
                        alt={data.product_name}
                      />
                    </Zoom>
                  </SubImage>
                  <SubImage
                    onClick={() => setMainImage(getImageUrl(data.images, 0))}
                  >
                    <Zoom>
                      <img
                        src={getImageUrl(data.images, 0)}
                        alt={data.product_name}
                      />
                    </Zoom>
                  </SubImage>
                </ProductLeftInner>
                <BuyButtons>
                  <Counter className="web">
                    <CounterLeft>
                      <p>{count}</p>
                    </CounterLeft>
                    <CounterRight>
                      <HiOutlinePlus onClick={handleIncrement} />

                      <AiOutlineMinus onClick={handleDecrement} />
                    </CounterRight>
                  </Counter>
                  <Counter className="mobile">
                    <AiOutlineMinus onClick={handleDecrement} />
                    <CounterLeft>
                      <p>{count}</p>
                    </CounterLeft>
                    <CounterRight>
                      <HiOutlinePlus onClick={handleIncrement} />
                    </CounterRight>
                  </Counter>
                  <BuyNowBtn onClick={() => handleBuyNow(data?.product_id)}>
                    Buy Now
                  </BuyNowBtn>
                  <Btn onClick={() => handleAddCart(data?.product_id)}>
                    <BsCart3 />
                  </Btn>
                  <Btn
                    onClick={() => handleAddToWishList(data?.product_id)}
                    className="wishlist"
                  >
                    <IoMdHeartEmpty />
                  </Btn>
                </BuyButtons>
              </ProductLeft>
              <ProductRight>
                <ProductName>{data.product_name}</ProductName>
                {/* <Extra>(rosewood)</Extra> */}
                <ProductPrice>
                  {data.mrp >= data.sale_price ? (
                    <>
                      {/* <span>
                        <del>₹ 1050</del>
                      </span> */}
                      <span className="main-price">₹900</span>
                    </>
                  ) : (
                    <>
                      <span>
                        <del>₹ {data.mrp}</del>
                      </span>
                      <span className="main-price">₹ {data.sale_price}</span>
                    </>
                  )}
                </ProductPrice>
                <RatingContainer>
                  <Rating
                    name="hover-feedback"
                    precision={0.5}
                    // onChange={(event, newValue) => {
                    //   setValue(newValue);
                    // }}
                    // onChangeActive={(event, newHover) => {
                    //   setHover(newHover);
                    // }}
                    emptyIcon={
                      <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
                    }
                    value={data.average_stars}
                  />
                </RatingContainer>
                <Seller>
                  {" "}
                  Sold By: <span>{data.vendor_name}</span>
                </Seller>
                <DescriptionToText htmlContent={data.product_description} />

                <DetailBox>
                  <H5>Product Details</H5>
                  <P>
                    <b>Materials :</b>{" "}
                    {data.material === null ? "N/A" : data.material}
                    <br />
                    <br />
                    {/* TODO:Make it dynamic */}
                    <b>Composition :</b> Handcrafted with great detail out of
                    Rosewood and embellished with brass accents
                  </P>
                </DetailBox>
              </ProductRight>
            </Product>
            <ProductMobile className="mobile">
              <ProductLeft>
                <div className="slider-container">
                  <Slider {...settings}>
                    <MobileImage>
                      <Zoom>
                        <img
                          src={
                            mainImage === ""
                              ? producturl + data.thumbnail
                              : mainImage
                          }
                        />
                      </Zoom>
                    </MobileImage>

                    <MobileImage
                      onClick={() => setMainImage(producturl + data.thumbnail2)}
                    >
                      <Zoom>
                        <img
                          src={producturl + data.thumbnail2}
                          alt={data.product_name}
                        />
                      </Zoom>
                    </MobileImage>
                    <MobileImage
                      onClick={() => setMainImage(getImageUrl(data.images, 1))}
                    >
                      <Zoom>
                        <img
                          src={getImageUrl(data.images, 1)}
                          alt={data.product_name}
                        />
                      </Zoom>
                    </MobileImage>
                    <MobileImage
                      onClick={() => setMainImage(getImageUrl(data.images, 0))}
                    >
                      <Zoom>
                        <img
                          src={getImageUrl(data.images, 0)}
                          alt={data.product_name}
                        />
                      </Zoom>
                    </MobileImage>
                  </Slider>
                </div>

                <BuyButtons>
                  <Counter className="web">
                    <CounterLeft>
                      <p>{count}</p>
                    </CounterLeft>
                    <CounterRight>
                      <HiOutlinePlus onClick={handleIncrement} />

                      <AiOutlineMinus onClick={handleDecrement} />
                    </CounterRight>
                  </Counter>
                  <Counter className="mobile">
                    <AiOutlineMinus onClick={handleDecrement} />
                    <CounterLeft>
                      <p>{count}</p>
                    </CounterLeft>
                    <CounterRight>
                      <HiOutlinePlus onClick={handleIncrement} />
                    </CounterRight>
                  </Counter>
                  <BuyNowBtn onClick={() => handleBuyNow(data?.product_id)}>
                    Buy Now
                  </BuyNowBtn>
                  <Btn onClick={() => handleAddCart(data?.product_id)}>
                    <BsCart3 />
                  </Btn>
                  <Btn
                    onClick={() => handleAddToWishList(data?.product_id)}
                    className="wishlist"
                  >
                    <IoMdHeartEmpty />
                  </Btn>
                </BuyButtons>
              </ProductLeft>
              <ProductRight>
                <ProductName>{data.product_name}</ProductName>
                {/* <Extra>(rosewood)</Extra> */}
                <ProductPrice>
                  {data.mrp >= data.sale_price ? (
                    <>
                      {/* <span>
                        <del>₹ 1050</del>
                      </span> */}
                      <span className="main-price">₹900</span>
                    </>
                  ) : (
                    <>
                      <span>
                        <del>₹ {data.mrp}</del>
                      </span>
                      <span className="main-price">₹ {data.sale_price}</span>
                    </>
                  )}
                </ProductPrice>
                <RatingContainer>
                  <Rating
                    name="hover-feedback"
                    precision={0.5}
                    // onChange={(event, newValue) => {
                    //   setValue(newValue);
                    // }}
                    // onChangeActive={(event, newHover) => {
                    //   setHover(newHover);
                    // }}
                    emptyIcon={
                      <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
                    }
                    value={data.average_stars}
                  />
                </RatingContainer>
                <Seller>
                  {" "}
                  Sold By: <span>{data.vendor_name}</span>
                </Seller>
                <DescriptionToText htmlContent={data.product_description} />

                <DetailBox>
                  <H5>Product Details</H5>
                  <P>
                    <b>Materials :</b>{" "}
                    {data.material === null ? "N/A" : data.material}
                    <br />
                    <br />
                    {/* TODO:Make it dynamic */}
                    <b>Composition :</b> Handcrafted with great detail out of
                    Rosewood and embellished with brass accents
                  </P>
                </DetailBox>
              </ProductRight>
            </ProductMobile>
            <SwitchButtonContainer>
              <SwitchButton
                className={displayData === "description" ? "active" : ""}
                onClick={() => handleSetDisplayData("description")}
              >
                Description
              </SwitchButton>
              <SwitchButton
                className={displayData === "reviews" ? "active" : ""}
                onClick={() => handleSetDisplayData("reviews")}
              >
                Reviews
              </SwitchButton>
              <SwitchButton
                className={displayData === "safety" ? "active" : ""}
                onClick={() => handleSetDisplayData("safety")}
              >
                Safety & Precautions
              </SwitchButton>
              <SwitchButton
                className={displayData === "vendor" ? "active" : ""}
                onClick={() => handleSetDisplayData("vendor")}
              >
                Vendor
              </SwitchButton>
            </SwitchButtonContainer>
            {displayData === "description" ? (
              <>
                <H2>A Story in Every Detail:</H2>
                <P>
                  <DescriptionToText htmlContent={data.product_description} />
                  <DescriptionToText htmlContent={data.highlights} />

                  <DescriptionToText htmlContent={data.benefits} />

                  <DescriptionToText htmlContent={data.usages} />
                </P>
              </>
            ) : displayData === "reviews" ? (
              <Reviews>
                <H2>Customer Reviews</H2>

                <H4 className="heading">Top reviews from India</H4>
                {data && data.comments && data.comments.length > 0 ? (
                  data.comments.map((comment) => {
                    return (
                      <ReviewBox key={comment.comment_id}>
                        <Left>
                          <IconContainer>
                            <img
                              src="/storage/general/favicon.png"
                              alt="user"
                            />
                          </IconContainer>
                        </Left>
                        <Right>
                          <H5>
                            {comment.first_name} {comment.last_name}
                          </H5>
                          <Rating
                            name="read-only"
                            value={comment.stars}
                            readOnly
                          />
                          <P className="review">
                            Reviewed in India on {comment.created_at}
                          </P>
                          <span>Verified Purchase</span>
                          <P className="review">{comment.comment}</P>
                        </Right>
                      </ReviewBox>
                    );
                  })
                ) : (
                  <p>No Reviews Yet</p>
                )}

                <form>
                  <h4>Add A Review</h4>
                  <Rating
                    name="no-value"
                    value={rating}
                    onChange={(event, newValue) => {
                      console.log(newValue); // Log the new value before setting the state
                      setRating(newValue);
                    }}
                  />
                  <TextArea
                    rows={5}
                    placeholder="Write a Product Review"
                    id="comment"
                    value={review}
                    onChange={(event) => setReview(event.target.value)}
                  />
                  <Button onClick={handleSubmit}>Submit</Button>
                </form>
              </Reviews>
            ) : displayData === "safety" ? (
              <>
                <DescriptionToText htmlContent={data.safety} />
              </>
            ) : displayData === "vendor" ? (
              <Vendor>
                <VendorName>{data.vendor_name}</VendorName>
                <Address>
                  <span>
                    <SlLocationPin fontSize={20} />
                  </span>
                  <b>Address: </b>
                  <a href="">{data.company_address}</a>
                </Address>
                <Desc>{data.vendor_description}</Desc>
              </Vendor>
            ) : null}
          </main>
          <TopSelling>
            <div className="wrapper">
              <TopSelling>
                <h5>Top Selling</h5>
                <Container className="top-selling">
                  <div className="slider-containerx">
                    <Slider ref={sliderRef} {...Topsettings}>
                      {topSellingData &&
                        topSellingData.length > 0 &&
                        topSellingData.map((item) => {
                          return (
                            <Card key={item.product_id}>
                              <Top>
                                <LikeBtn
                                  onClick={() =>
                                    handleAddToWishList(item.product_id)
                                  }
                                >
                                  {true ? <CiHeart /> : <VscHeartFilled />}
                                </LikeBtn>
                                <ImageContainer
                                  onClick={() =>
                                    handleProductRoute(item.product_slug)
                                  }
                                  className="top-selling"
                                >
                                  <img
                                    src={producturl + item.thumbnail}
                                    alt="Image not found"
                                  />
                                </ImageContainer>
                              </Top>
                              <Bottom className="top-selling">
                                <div>
                                  <h3
                                    onClick={() =>
                                      handleProductRoute(item.product_slug)
                                    }
                                  >
                                    {item.product_name}
                                  </h3>
                                  <Rating
                                    name="hover-feedback"
                                    value={item.average_stars}
                                    precision={0.5}
                                    onChange={(event, newValue) => {
                                      setValue(newValue);
                                    }}
                                    onChangeActive={(event, newHover) => {
                                      setHover(newHover);
                                    }}
                                    emptyIcon={
                                      <StarIcon
                                        style={{ opacity: 0.55 }}
                                        fontSize="inherit"
                                      />
                                    }
                                  />
                                  <DescriptionButton>
                                    <p>
                                      {trimDescriptionToTen(item.description)}
                                    </p>
                                    <div>
                                      <button
                                        onClick={() =>
                                          handleAddCart(item.product_id)
                                        }
                                      >
                                        <PiShoppingCartThin />
                                      </button>
                                    </div>
                                  </DescriptionButton>
                                </div>
                              </Bottom>
                            </Card>
                          );
                        })}
                    </Slider>
                  </div>
                </Container>
                <ButtonContainer>
                  <TopSellingButton onClick={handlePrev}>
                    <HiOutlineArrowLeft />
                  </TopSellingButton>
                  <TopSellingButton onClick={handleNext}>
                    <HiOutlineArrowRight />
                  </TopSellingButton>
                </ButtonContainer>
              </TopSelling>
            </div>
          </TopSelling>
          <ScrollToTop />
        </div>
      ) : (
        <BuyNow
          data={data}
          count={count}
          handleIncrement={handleIncrement}
          handleDecrement={handleDecrement}
        />
      )}
    </>
  );
}
const DescriptionToText = ({ htmlContent }) => {
  return (
    <P
      style={{ whiteSpace: "pre-wrap" }}
      dangerouslySetInnerHTML={{ __html: htmlContent }}
    />
  );
};
export default ProductFullWidthNew;

const Product = styled.div`
  gap: 30px;
  padding-top: 60px;
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  gap: 50px;

  @media screen and (max-width: 1080px) {
    grid-template-columns: 1fr;
    padding-top: unset;
    display: none;
  }
`;
const ProductMobile = styled.div`
  display: none;
  @media screen and (max-width: 1080px) {
    display: block;
  }
`;
const ProductLeft = styled.div``;
const ProductLeftInner = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
`;

const ProductRight = styled.div``;

const MainImage = styled.div`
  grid-column: span 3;
  height: 400px;
  width: 100%;
  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    display: block;
  }
  @media screen and (max-width: 540px) {
    height: 300px;
  }
`;
const MobileImage = styled.div`
  height: 400px;
  width: 100%;
  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    display: block;
  }
`;
const SubImages = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  margin-top: 20px;
  @media screen and (max-width: 540px) {
    gap: 10px;
  }
`;
const SubImage = styled.div`
  height: 200px;
  width: 100%;
  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    display: block;
  }
  @media screen and (max-width: 540px) {
    height: 100px;
  }
`;
const ProductName = styled.h2`
  font-weight: 600;
  color: #000;
  font-size: 45px;
  @media screen and (max-width: 1080px) {
    font-size: 35px;
  }
`;
const Extra = styled.h2`
  font-weight: 600;
  color: #000;
  font-size: 35px;
  @media screen and (max-width: 1080px) {
    font-size: 25px;
  }
`;
const ProductPrice = styled.h3`
  font-weight: 600;
  display: flex;
  gap: 20px;
  margin: 20px 0;
  color: #000;
  del {
    text-decoration: line-through;
    text-decoration-color: red; /* Change the line color */
    text-decoration-thickness: 3px; /* Adjust line thickness */
  }
  span {
    &.main-price {
      color: #029d49;
    }
  }
`;
const Seller = styled.p`
  color: #000;

  span {
    color: #029d49;
    font-weight: 600;
  }
`;
const DetailBox = styled.div`
  display: inline-block;
  border: 1px solid #777777;
  border-radius: 10px;
  padding: 20px;
  h5 {
    color: #029d49;
  }
`;

const H2 = styled.h2`
  font-weight: 600;
  padding: 15px 0;
  color: #000;
`;
const H5 = styled.h5`
  font-weight: 500;
  padding: 15px 0;
  color: #000;
`;

const H4 = styled.h4`
  font-weight: 500;
  padding: 15px 0;
  color: #000;

  &.heading {
    padding: 8px 0;
  }
`;
const P = styled.p`
  padding: 15px 0;
  p {
    color: #000;
  }
  color: #000;

  &.review {
    padding: 0;
    margin-bottom: 0;
  }
  /* &.main {
    padding: 25px 0;
  } */
`;
const Head = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
`;
const TextArea = styled.textarea`
  border-radius: 10px;
  border: 1px solid #b1b1b1;
`;
const Reviews = styled.div`
  form {
    display: grid;
    gap: 12px;
  }
`;
const ReviewBox = styled.div`
  display: grid;
  grid-template-columns: 0.3fr 2fr;
  gap: 20px;
  margin: 20px 0;
  border-bottom: 1px solid black;
  padding-bottom: 20px;
  /* &:last-child {
    border-bottom: none;
  } */
`;
const Left = styled.div`
  justify-self: end;
`;
const IconContainer = styled.div`
  height: 60px;
  width: 60px;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 50%;
    display: block;
    border: 1px solid black;
  }
`;

const Right = styled.div`
  span.verified {
    font-size: 13px;
    color: #c22d2d;
    font-weight: 500;
    padding: 0px 0 10px 0;
  }
`;
const Button = styled.button`
  background-color: #084c27;
  border-radius: 8px;
  color: #fff;
  font-weight: 500;
  border: none;
  padding: 10px 30px;

  justify-self: end;
`;
const TopSelling = styled.div`
  padding-top: 20px;
`;
const Card = styled.div`
  /* border: 1px solid #636363;
  box-shadow: 1px 1px 14px #636363; */
  &.popular {
    border: 1px solid black;
  }
`;
const Top = styled.div`
  position: relative;
  &.popular-left {
    position: unset;
  }
`;
const Bottom = styled.div`
  &.top-selling {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    align-items: end;
    margin: 20px 0;
    padding: 5px 15px;
    button {
      border: none;
      background-color: #389763;
      border-radius: 6px;
      padding: 8px 10px;
      svg {
        font-size: 20px;
        color: #fff;
      }
    }
    h3 {
      font-size: 22px;
      min-height: 55px;
    }
  }
  &.deals {
    background-color: #f0f0f0;
    display: flex;
    justify-content: space-between;
    gap: 20px;
    align-items: end;

    padding: 10px 15px;
    padding-top: 20px;

    button {
      border: none;
      background-color: #389763;
      border-radius: 6px;
      padding: 8px 10px;
      svg {
        font-size: 20px;
        color: #fff;
      }
    }
  }

  &.deals-right {
    background-color: #ad4b0e;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    gap: 20px;
    align-items: end;
    h3,
    p {
      color: #fff;
      font-weight: 500;
    }

    button {
      border: none;
      background-color: #fff;
      border-radius: 6px;
      padding: 8px 10px;
      svg {
        font-size: 20px;
        color: #ad4b0e;
      }
    }
    .shop-button {
      margin-top: 20px;
      border-radius: 10px;
      padding: 8px 10px;
      border: none;
      display: flex;
      align-items: center;
      gap: 20px;
      border: none;
      border-radius: 6px;
      background-color: #fc9800;
      color: #fff;
      font-size: 17px;
      svg {
        color: #fff;
      }
    }
  }
  &.popular-left {
    padding: 20px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    gap: 20px;
    align-items: end;
    padding-top: 25px;
    p {
      color: #000;
    }
    h3 {
      color: #fff;
      font-size: 23px;
      font-weight: 500;
      color: #ad4b0e;
    }
    button {
      border: none;
      background-color: #fc9800;
      border-radius: 6px;
      padding: 8px 22px;
      color: #fff;
      font-weight: 500;
    }
  }
  &.popular {
    padding: 20px;
    background-color: #ad4b0e;
    display: flex;
    justify-content: space-between;
    gap: 20px;
    align-items: end;
    padding-top: 25px;
    p {
      color: #fff;
    }
    h3 {
      color: #fff;

      font-size: 17px;
      font-weight: 500;
    }
    button {
      border: none;
      background-color: #fff;
      border-radius: 6px;
      padding: 8px 10px;
      svg {
        font-size: 20px;
        color: #ad4b0e;
      }
    }
  }
`;
const Container = styled.div`
  border: 1px solid #636363;
  padding: 10px;
  margin: 20px 0;
  .slick-slide {
    padding-right: 15px;
    height: unset;
  }
`;
const LikeBtn = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  background-color: #f0f0f0;
  display: inline-block;
  padding: 10px;
  svg {
    font-size: 20px;
    color: #ff0000;
  }
`;
const ImageContainer = styled.div`
  height: 300px;
  width: 100%;
  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
    display: block;
  }
`;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  width: 100%;
  gap: 20px;
`;
const TopSellingButton = styled.div`
  background-color: #084c27;
  padding: 10px;
  cursor: pointer;
  svg {
    font-size: 20px;
    color: #fff;
  }
`;
const SwitchButtonContainer = styled.div`
  display: flex;
  gap: 20px;
  margin: 20px 0;

  @media screen and (max-width: 640px) {
    flex-wrap: wrap;
  }
`;
const SwitchButton = styled.button`
  flex-shrink: 0;
  border-radius: 6px;
  border: 1px solid #b1b1b1;
  background: transparent;
  color: #858585;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: 500;
  &.active {
    background-color: #fc9800;
    border: 1px solid #fc9800;
    color: #ffff;
  }
  @media screen and (max-width: 640px) {
    font-size: 14px;
  }
`;
const Vendor = styled.div`
  margin: 20px 0;
`;
const VendorName = styled.h2`
  margin: 20px 0;
  font-weight: 600;
`;
const Address = styled.p`
  svg {
    margin-right: 10px;
  }
`;
const Desc = styled.p``;
const RatingContainer = styled.div`
  padding: 10px 0;
  border: 1px solid #d9d9d9;
  border-right: none;
  border-left: none;
  margin: 20px 0;
`;
const BuyButtons = styled.div`
  display: grid;
  grid-template-columns: 0.3fr 3fr 0.2fr 0.2fr;
  align-items: center;
  gap: 20px;
  margin: 20px 0;

  @media screen and (max-width: 640px) {
    grid-template-columns: 1fr;
  }
`;
const Counter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #084c27;
  border-radius: 6px;

  @media screen and (max-width: 640px) {
    justify-self: center;
  }
  @media screen and (max-width: 640px) {
    &.web {
      display: none;
    }
  }
  &.mobile {
    display: none;
    @media screen and (max-width: 640px) {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 25px;
      border: none;
      svg {
        font-size: 35px;
        background-color: #f4f4f4;
        padding: 10px;
        border-radius: 10px;
      }
    }
  }
`;
const CounterLeft = styled.div`
  p {
    font-size: 18px;
    padding: 5px 12px;
  }
`;
const CounterRight = styled.div`
  display: grid;
  justify-items: end;
  border-left: 1px solid black;
  svg {
    cursor: pointer;
    padding: 3px 0px;
    display: inline-block;
    font-size: 20px;
    border-bottom: 1px solid black;

    &:last-child {
      border-bottom: none;
    }
  }
  @media screen and (max-width: 640px) {
    border-left: unset;
  }
`;

const BuyNowBtn = styled.button`
  border: 1px solid #084c27;
  background-color: #084c27;
  border-radius: 6px;
  color: #fff;
  font-size: 18px;
  text-transform: capitalize;
  padding: 10px 20px;
`;
const Btn = styled.button`
  border: 1px solid #fc9800;
  background-color: #fc9800;
  border-radius: 6px;
  padding: 10px;

  svg {
    font-size: 20px;
    color: #fff;
  }
  &.cart {
  }
  &.whishlist {
  }
`;

//

const DescriptionButton = styled.div`
  display: flex;
  align-items: end;
  justify-content: space-between;

  @media screen and (max-width: 640px) {
    flex-direction: column;
    align-items: start;
  }
`;
