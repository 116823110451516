import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { backend } from "../../../path";
import DashboardSidebarMain from "../dashsidebar";

import Pagination from "../../components/pagination";
import styled from "styled-components";
import OrderChart from "./Graph";
import { initGA, logPageView } from "./Analytics";
function UserNew() {
  // const [formData, setFormData] = useState([]);
  const [formData, setFormData] = useState({
    category_name: "",
    description: "", // Assuming description is a string
    logo: "", // Assuming logo is a string
  });
  const [data, setData] = useState([]);
  const [error, setError] = useState();
  const [loading, setLoading] = useState();
  const [view, setView] = useState(0);
  const [logo, setLogo] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [dummyData, setDummyData] = useState({});
  const [authenticated, setAuthenticated] = useState(
    JSON.parse(localStorage.getItem("userdata"))
  );
  const [newPageNumber, setNewPageNumber] = useState(99999);
  const [searchInput, setSearchInput] = useState("");
  const searchParams = new URLSearchParams({
    // page_number: pageNumber,
    page_size: newPageNumber,
    search: searchInput,
  });
  useEffect(() => {
    initGA();
    logPageView();
  }, []);
  const [allOrders, setAllOrders] = useState(null);
  const [activeOrders, setactiveOrders] = useState(null);
  const [pendingOrders, setpendingeOrders] = useState(null);
  const [canceledOrders, setcanceledOrders] = useState(null);
  const [completedOrders, setcompletedOrders] = useState(null);
  const fetchData = () => {
    // Assuming setData, setError, and setLoading are state functions from useState
    setLoading(true);

    fetch(
      backend +
        "getuserlog?page_number=" +
        pageNumber +
        "&page_size=" +
        pageSize,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${authenticated?.access_token}`,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setData(data);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const fetchAllOrders = async () => {
    try {
      const response = await fetch(backend + "getallorders?" + searchParams, {
        method: "GET",
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      setAllOrders(data);
    } catch (error) {
      console.error("Error fetching confirmed orders:", error);
    }
  };
  const fetchActiveOrders = async () => {
    try {
      const response = await fetch(
        backend + "getcurrentorders?" + searchParams,
        {
          method: "GET",
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      setactiveOrders(data);
    } catch (error) {
      console.error("Error fetching confirmed orders:", error);
    }
  };
  const fetchPendingOrders = async () => {
    try {
      const response = await fetch(
        backend + "getpendingorders?" + searchParams,
        {
          method: "GET",
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      setpendingeOrders(data);
    } catch (error) {
      console.error("Error fetching confirmed orders:", error);
    }
  };
  const fetchCanceledOrders = async () => {
    try {
      const response = await fetch(
        backend + "getcancelledorders?" + searchParams,
        {
          method: "GET",
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      setcanceledOrders(data);
    } catch (error) {
      console.error("Error fetching confirmed orders:", error);
    }
  };
  const fetchCompletedOrders = async () => {
    try {
      const response = await fetch(
        backend + "getdeliveredorders?" + searchParams,
        {
          method: "GET",
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      setcompletedOrders(data);
    } catch (error) {
      console.error("Error fetching confirmed orders:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [pageNumber, pageSize]);

  useEffect(() => {
    fetchAllOrders();
    fetchActiveOrders();
    fetchPendingOrders();
    fetchPendingOrders();
    fetchCanceledOrders();
    fetchCompletedOrders();
  }, []);
  console.log(allOrders);

  const handlePageClick = (pageNumber) => {
    setPageNumber(pageNumber);
  };

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-lg-2 d-none d-lg-flex">
            <DashboardSidebarMain />
          </div>
          <Right>
            <BoxContainer>
              <Box>
                <div className="left"></div>
                <div className="right">
                  <H3>{allOrders?.data.length}</H3>
                  <P>Total Orders</P>
                </div>
              </Box>
              <Box>
                <div className="left"></div>
                <div className="right">
                  <H3>{activeOrders?.data.length}</H3>
                  <P>Confirmed Orders</P>
                </div>
              </Box>
              <Box>
                <div className="left"></div>
                <div className="right">
                  <H3>{pendingOrders?.data.length}</H3>
                  <P>Pending Orders</P>
                </div>
              </Box>
              <Box>
                <div className="left"></div>
                <div className="right">
                  <H3>{canceledOrders?.data.length}</H3>
                  <P>Canceled Orders</P>
                </div>
              </Box>
              <Box>
                <div className="left"></div>
                <div className="right">
                  <H3>{completedOrders?.data.length}</H3>
                  <P>Delivered Orders</P>
                </div>
              </Box>
            </BoxContainer>
            <OrderChart orders={allOrders} />
          </Right>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}
export default UserNew;
const Right = styled.div`
  position: absolute;
  right: 0;
  width: calc(100% - 250px);
  @media screen and (max-width: 980px) {
    width: 100%;
  }
`;
const BoxContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 30px;
  @media screen and (max-width: 980px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;
const Box = styled.div`
  border: 1px solid #6f6f6f;
  padding: 20px;
  border-radius: 10px;
`;
const H3 = styled.h3`
  font-weight: 500;
  font-size: 42px;
  text-align: right;
`;
const P = styled.p`
  font-weight: 300;
  text-align: 16px;
  text-align: right;
`;
