import { useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import HeaderMain from "./app/layout/header";
import Registration from "./app/pages/registration/registration";
import FooterMain from "./app/layout/header/footer";
import Login from "./app/pages/registration/login";
import Homepage from "./app/pages/registration/homepage";
import DashboardMain from "./app/dash/main";
import DashboardVendorMain from "./app/dash/vendor";
import DashboardVendorList from "./app/dash/vendor/form";
import DashboardCategoryMain from "./app/dash/category";
import DashboardCategoryList from "./app/dash/category/form";
import DashboardSubCategoryMain from "./app/dash/sub_category";
import DashboardSubCategoryList from "./app/dash/sub_category/form";
import DashboardProductMain from "./app/dash/product";
import DashboardProductList from "./app/dash/product/form";
import ProductFullWidth from "./app/pages/registration/product full width";
import ProductListing from "./app/pages/registration/products_listing_page";
import AboutUs from "./app/pages/registration/about_us";
import Cart from "./app/pages/registration/cart";
import PasswordReset from "./app/pages/registration/reset password";
import VendorRegistration from "./app/pages/registration/registration/vendor registration";
import Policy from "./app/pages/registration/policy";
import AdminUser from "./app/dash/admin_user";
import AdminUserForm from "./app/dash/admin_user/form";
import DashboardOrderMain from "./app/dash/orders";

import DashboardOrdersList from "./app/dash/orders/form";
import DashboardDealsMain from "./app/dash/Deal of the Day";
import DashboardPopularMain from "./app/dash/Popular Products";
import { backend } from "./path";
import SearchList from "./app/pages/registration/search_list";
import ConfirmedOrder from "./app/dash/main orders/confirmedOrder";
import PendingOrder from "./app/dash/main orders/pending order";
import OrderHistory from "./app/dash/main orders/order history";
import DashboardTopSellingMain from "./app/dash/Top Selling";
import DashboardTrendingMain from "./app/dash/Trending Products";
import DashTrendingList from "./app/dash/Trending Products/form";
import DashboardRecentlyAddedMain from "./app/dash/Recently Added";
import DashboardTopRatedMain from "./app/dash/Top Rated";
import ContactUs from "./app/pages/registration/contact_us";
import CategoryListingMain from "./app/pages/registration/products_listing_category_page";
import ListingProductCategory from "./app/pages/registration/products_listing_category_page";
import DashboardCarousel from "./app/dash/banner";
import MyOrdersUser from "./app/pages/registration/my_orders";
import DashboardBannerMain from "./app/dash/banner new";
import DashboardBannerForm from "./app/dash/banner new/form";
import User from "./app/dash/consumers";
import DashboardReviewsMain from "./app/dash/Reviews";
import DashboardCouponMain from "./app/dash/coupon";
import DashboardCouponForm from "./app/dash/coupon/form";
import ReturnRefund from "./app/pages/registration/return and refund";
import PasswordResetUser from "./app/pages/registration/reset password user";
import DashboardBlogMain from "./app/dash/blog";
import DashboardBlogList from "./app/dash/blog/form";
import VendorRegUser from "./app/dash/vendor/form user";
import BlogUser from "./app/pages/registration/blog_user";
import BlogUserDetail from "./app/pages/registration/blog_user/blogdetail";
import Page1 from "./app/pages/registration/page_1";
import DashboardContactUsMain from "./app/dash/Admin Contact Us";
import PaymentSuccess from "./app/pages/registration/payment success page";
import Page2 from "./app/pages/registration/page_2";
import Page3 from "./app/pages/registration/page_3";
import OldUserReset from "./app/pages/registration/olduser password reset";
import ErrorPage from "./app/pages/registration/404 Error";
import LoaderMain from "./app/layout/header/loader_sub";
import CancelledOrder from "./app/dash/main orders/canceled order";
import DashboardKeralaGemMain from "./app/dash/Kerala Gem";
import DashboardFeaturedFinds from "./app/dash/Featured finds";
import DashboardAyurDelight from "./app/dash/Ayurvedic Delight";
import BulkMail from "./app/dash/consumers/bulKmail";
import DashboardGroupMain from "./app/dash/product_group";
import DashboardGroupList from "./app/dash/product_group/form";
import SpecialOccation from "./app/pages/registration/homepage/specialOccation";
import FooterMainNew from "./app/layout/header/footer/Index.jsx";
import HeaderMainNew from "./app/layout/header/Index.jsx";
import HomepageNew from "./app/pages/registration/homepage/Index.jsx";
import AboutUsNew from "./app/pages/registration/about_us/Index.jsx";
import ProductFullWidthNew from "./app/pages/registration/product full width/Index.jsx";
import WishList from "./app/pages/registration/wishlist/index.js";
import SubCategoryListingMainNew from "./app/pages/registration/products_listing_page/Index.jsx";
import UserNew from "./app/dash/consumers/Index.jsx";
// import ReturnRefund from "./app/pages/registration/return policy";

function App() {
  const [authenticated, setAuthenticated] = useState(
    JSON.parse(localStorage.getItem("userdata"))
  );
  const [search, setSearch] = useState("");
  const [data, setData] = useState([]);
  const [error, setError] = useState();
  const [loading, setLoading] = useState();
  const [cartChange, setCartChange] = useState(false);
  const [wishChange, setWishChange] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    fetchData();
  }, [search]);

  const fetchData = () => {
    // Assuming setData, setError, and setLoading are state functions from useState
    setLoading(true);

    fetch(backend + "getproducts?search=" + search, {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        setData(data);
      })
      .catch((error) => {
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleSearchSubmit = (event) => {
    event.preventDefault();
    fetchData();
  };
  const handleSearchEsc = () => {
    setSearch("");
    fetchData();
  };

  return (
    <div>
      <BrowserRouter>
        {/* <BrowserRouter basename={url}> */}
        {loading && <LoaderMain />}
        {/* <HeaderMain
          search={search}
          setSearch={setSearch}
          cartChange={cartChange}
          wishChange={wishChange}
          handleSearchSubmit={handleSearchSubmit}
          SearchFetch={handleSearchEsc}
        /> */}
        <HeaderMainNew
          search={search}
          setSearch={setSearch}
          cartChange={cartChange}
          wishChange={wishChange}
          handleSearchSubmit={handleSearchSubmit}
          SearchFetch={handleSearchEsc}
        />
        {search ? (
          <SearchList
            data={data}
            search={search}
            setSearch={setSearch}
            setCartChange={setCartChange}
            setWishChange={setWishChange}
            SearchFetch={handleSearchEsc}
          />
        ) : authenticated?.usertype === null ? (
          <Routes>
            <Route
              path="/"
              element={
                <HomepageNew
                  setCartChange={setCartChange}
                  setWishChange={setWishChange}
                  cartChange={cartChange}
                  wishChange={wishChange}
                />
              }
            />
            <Route
              path="/onamgift"
              element={
                <SpecialOccation
                  setCartChange={setCartChange}
                  setWishChange={setWishChange}
                  cartChange={cartChange}
                  wishChange={wishChange}
                />
              }
            />
            <Route
              path="/subcategory/:id"
              element={
                <SubCategoryListingMainNew
                  setCartChange={setCartChange}
                  setWishChange={setWishChange}
                  cartChange={cartChange}
                  wishChange={wishChange}
                />
              }
            />
            <Route path="/category" element={<ListingProductCategory />} />

            <Route
              path="/product/:id"
              element={
                <ProductFullWidthNew
                  setCartChange={setCartChange}
                  cartChange={cartChange}
                  setWishChange={setWishChange}
                  wishChange={wishChange}
                />
              }
            />
            <Route path="/register" element={<Registration />} />
            <Route path="/login" element={<Login />} />
            <Route path="/user/reminder" element={<OldUserReset />} />
            <Route path="/policy" element={<Policy />} />
            <Route
              path="/registration/vendor"
              element={<VendorRegistration />}
            />
            <Route path="/about-us" element={<AboutUsNew />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route
              path="/cart"
              element={<Cart setCartChange={setCartChange} />}
            />
            <Route path="/myorders" element={<MyOrdersUser />} />
            <Route
              path="/Unveiling Ayurvedic Delights for the Day"
              element={
                <Page1
                  setCartChange={setCartChange}
                  setWishChange={setWishChange}
                  cartChange={cartChange}
                  wishChange={wishChange}
                />
              }
            />
            <Route
              path="/Todays Featured Finds"
              element={
                <Page2
                  setCartChange={setCartChange}
                  setWishChange={setWishChange}
                  cartChange={cartChange}
                  wishChange={wishChange}
                />
              }
            />
            <Route
              path="/Unveiling Kerala's Gems"
              element={
                <Page3
                  setCartChange={setCartChange}
                  setWishChange={setWishChange}
                  cartChange={cartChange}
                  wishChange={wishChange}
                />
              }
            />
            <Route path="/forget-password" element={<PasswordReset />} />
            <Route path="/:id" element={<ErrorPage />} />
          </Routes>
        ) : authenticated?.usertype === 1 ? (
          <Routes>
            <Route
              path="/"
              element={
                <HomepageNew
                  setCartChange={setCartChange}
                  setWishChange={setWishChange}
                  cartChange={cartChange}
                  wishChange={wishChange}
                />
              }
            />
            <Route
              path="/subcategory/:id"
              element={
                <SubCategoryListingMainNew
                  setCartChange={setCartChange}
                  setWishChange={setWishChange}
                  cartChange={cartChange}
                  wishChange={wishChange}
                />
              }
            />
            <Route path="/category" element={<ListingProductCategory />} />
            <Route
              path="/category/:id"
              element={
                <ListingProductCategory
                  setCartChange={setCartChange}
                  setWishChange={setWishChange}
                  cartChange={cartChange}
                  wishChange={wishChange}
                />
              }
            />
            <Route
              path="/product/:id"
              element={
                <ProductFullWidthNew
                  setCartChange={setCartChange}
                  cartChange={cartChange}
                  setWishChange={setWishChange}
                  wishChange={wishChange}
                />
              }
            />
            <Route path="/register" element={<Registration />} />
            <Route path="/reset-password" element={<PasswordResetUser />} />
            <Route path="/success" element={<PaymentSuccess />} />
            <Route path="/about-us" element={<AboutUsNew />} />
            <Route
              path="/cart"
              element={
                <Cart setCartChange={setCartChange} cartChange={cartChange} />
              }
            />
            <Route path="/myorders" element={<MyOrdersUser />} />
            <Route
              path="/wishlist"
              element={
                <WishList
                  setCartChange={setCartChange}
                  setWishChange={setWishChange}
                  cartChange={cartChange}
                  wishChange={wishChange}
                />
              }
            />
            <Route path="/policy" element={<Policy />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/return-refund" element={<ReturnRefund />} />
            <Route path="/blog" element={<BlogUser setSearch={setSearch} />} />
            <Route
              path="/blog/:id"
              element={<BlogUserDetail setSearch={setSearch} />}
            />
            <Route
              path="/Unveiling Ayurvedic Delights for the Day"
              element={
                <Page1
                  setCartChange={setCartChange}
                  setWishChange={setWishChange}
                  cartChange={cartChange}
                  wishChange={wishChange}
                />
              }
            />
            <Route
              path="/Todays Featured Finds"
              element={
                <Page2
                  setCartChange={setCartChange}
                  setWishChange={setWishChange}
                  cartChange={cartChange}
                  wishChange={wishChange}
                />
              }
            />
            <Route
              path="/Unveiling Kerala's Gems"
              element={
                <Page3
                  setCartChange={setCartChange}
                  setWishChange={setWishChange}
                  cartChange={cartChange}
                  wishChange={wishChange}
                />
              }
            />
            <Route path="/:id" element={<ErrorPage />} />
            <Route
              path="/onamgift"
              element={
                <SpecialOccation
                  setCartChange={setCartChange}
                  setWishChange={setWishChange}
                  cartChange={cartChange}
                  wishChange={wishChange}
                />
              }
            />
          </Routes>
        ) : authenticated?.usertype === 0 ? (
          <div style={{ marginTop: "110px" }}>
            <Routes>
              <Route path="/" element={<DashboardMain />} />
              <Route path="/dash-vendor" element={<DashboardVendorMain />} />
              <Route
                path="/dash-vendor-add"
                element={<DashboardVendorList />}
              />
              <Route path="/admin-users" element={<AdminUser />} />
              <Route path="/user-analytics" element={<UserNew />} />
              <Route path="/mail" element={<BulkMail />} />
              <Route path="/admin-users-add" element={<AdminUserForm />} />
              <Route
                path="/dash-category"
                element={<DashboardCategoryMain />}
              />
              <Route
                path="/dash-category-add"
                element={<DashboardCategoryList />}
              />
              <Route path="/product-group" element={<DashboardGroupMain />} />
              <Route
                path="/product-group-add"
                element={<DashboardGroupList />}
              />
              <Route
                path="/dash-sub-category"
                element={<DashboardSubCategoryMain />}
              />
              <Route
                path="/dash-sub-category-add"
                element={<DashboardSubCategoryList />}
              />
              <Route path="/dash-product" element={<DashboardProductMain />} />
              <Route
                path="/dash-product-add"
                element={<DashboardProductList />}
              />
              <Route path="/banner" element={<DashboardBannerMain />} />
              <Route path="/banner-add" element={<DashboardBannerForm />} />
              <Route path="/coupons" element={<DashboardCouponMain />} />
              <Route path="/coupons-add" element={<DashboardCouponForm />} />
              <Route path="/blogs" element={<DashboardBlogMain />} />
              <Route path="/blogs-add" element={<DashboardBlogList />} />
              <Route path="/orders" element={<DashboardOrderMain />} />
              <Route path="/orders-active" element={<ConfirmedOrder />} />
              <Route path="/orders-pending" element={<PendingOrder />} />
              <Route path="/orders-cancelled" element={<CancelledOrder />} />
              <Route path="/orders-history" element={<OrderHistory />} />
              <Route path="/deals" element={<DashboardDealsMain />} />
              <Route path="/reviews" element={<DashboardReviewsMain />} />
              <Route
                path="/users-contactus"
                element={<DashboardContactUsMain />}
              />
              <Route path="/popular" element={<DashboardPopularMain />} />
              <Route
                path="/top_selling"
                element={<DashboardTopSellingMain />}
              />
              <Route path="/trending" element={<DashboardTrendingMain />} />
              <Route
                path="/recently"
                element={<DashboardRecentlyAddedMain />}
              />
              <Route path="/top-rated" element={<DashboardTopRatedMain />} />
              <Route path="/tab1" element={<DashboardKeralaGemMain />} />
              <Route path="/tab2" element={<DashboardFeaturedFinds />} />
              <Route path="/tab3" element={<DashboardAyurDelight />} />
              <Route path="/policy" element={<Policy />} />
              <Route path="/:id" element={<ErrorPage />} />
            </Routes>
          </div>
        ) : (
          <Routes>
            {/* <Route path="/" element={<Homepage />} /> */}
            <Route path="/" element={<HomepageNew />} />

            {/* <Route path="/onamgift" element={<SpecialOccation setCartChange={setCartChange} setWishChange={setWishChange} cartChange={cartChange} wishChange={wishChange} />} /> */}
            <Route
              path="/subcategory/:id"
              element={<SubCategoryListingMainNew />}
            />
            <Route path="/category" element={<ListingProductCategory />} />
            {/* <Route path="/product/:id" element={<ProductFullWidth />} /> */}
            <Route path="/product/:id" element={<ProductFullWidthNew />} />

            <Route path="/register" element={<Registration />} />
            <Route path="/login" element={<Login />} />
            <Route path="/user/reminder" element={<OldUserReset />} />
            <Route path="/registration-vendor" element={<VendorRegUser />} />
            <Route path="/category/:id" element={<ListingProductCategory />} />

            <Route path="/forgot-password" element={<PasswordReset />} />
            {/* <Route path="/about-us" element={<AboutUs />} /> */}
            <Route path="/about-us" element={<AboutUsNew />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/myorders" element={<MyOrdersUser />} />
            <Route path="/policy" element={<Policy />} />
            <Route path="/return-refund" element={<ReturnRefund />} />
            <Route path="/blog" element={<BlogUser />} />
            <Route path="/:id" element={<ErrorPage />} />
            <Route
              path="/blog/:id"
              element={<BlogUserDetail setSearch={setSearch} />}
            />
            <Route
              path="/Unveiling Ayurvedic Delights for the Day"
              element={<Page1 />}
            />
            <Route path="/Todays Featured Finds" element={<Page2 />} />
            <Route path="/Unveiling Kerala's Gems" element={<Page3 />} />
            <Route path="/forget-password" element={<PasswordReset />} />
          </Routes>
        )}

        <FooterMainNew />
      </BrowserRouter>
    </div>
  );
}

export default App;
